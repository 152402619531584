import MenuOption from "../menuOptions/menuOption"
import {PermissionSetting} from "../permissions/permission"
import Resource from '../resources/resource'

import {addDefaultLinks} from 'site/structure/menu/defaultMenu'

/* 

This is a class & data structure to hold all the resources, 
permissions, and menu options.  

This allows access to the whole "site" as an object.


GET FUNCTIONS
getMenu()- sort the menu by order & return it
getPermissionById()- Get a permission by the ID
getResourceById()
getResourceByFriendlyName()

INITIALIZE/CREATE FUNTIONS
initializeSite()
addPermission()
addResource()
addMenuOption()


*/


export default class AsteroidSite {
    constructor() {
        this.resources = []
        this.topMenu = []
        this.menu = []
        this.permissions = []
        this.userKinds = []
        this.userKindRoles = []
    }


    //==============
    //GET FUNCTIONS
    //==============
    getMenu = (menu) => {
        return this[menu].sort((a,b) => a.order - b.order)
    }

    getPermissionById = (id) => {
        let permission = null
        this.permissions.map(c => { if(c.permission_id === id) { permission = c } return "" })
        if(!permission) { 
            //throw new Error(`ASTEROID: Unable to find Permission id#:"${id}"`) 
            this.permissions.map(c => { if(c.permission_id === 1) { permission = c } return "" })
        }
        return permission
    }

    findResourceById = (id) => {
        let resource = null
        this.resources.map(c => { if(c.resource_id === id) { resource = c } return "" })
        if(!resource) { throw new Error(`ASTEROID: Unable to find Resource id#:"${id}"`) }
        return resource
    }

    findResourceByFriendlyName  = (path) => {
        let resource = null
        this.resources.map(c => { if(c.get('friendly') === path) { resource = c } return "" })
        if(!resource) { console.log(`ASTEROID: Unable to find Resource name:"${path}"`) }
        return resource
    }


    //==============
    // INITIALIZE
    //==============
    //The "Res" is a call from the backend that has the resources, menus, and permissions.
    //We loop over them and call the functions to add them to Site.
    initializeSite = (res) => {
        res = res.data
        res.permissions.map( perm => this.addPermission(perm) )
        res.resources.map( resource => this.addResource(resource) )
        res.resources.map( resource => { this.findResourceById(resource.resource_id).init(resource, this) }) 

        addDefaultLinks(this)

        //First do the ones without parents to make sure they're added into the system before doing the ones with parents
        res.menuOptions.filter( (m) => !m.parent_menu_id ).map( menuOpt => this.addMenuOption(menuOpt) )
        res.menuOptions.filter( (m) => m.parent_menu_id ).map( menuOpt => this.addMenuOption(menuOpt) )

        this.userKinds = res.userKinds
        this.userKindRoles = res.userKindRoles
    }


    //Simply call the right constructor class add add it into the array.
    addPermission = (data) => {
        let permission = new PermissionSetting(data)
        this.permissions.push(permission)
    }
    addResource = (data) => {
        let resource = new Resource(data, this)
        this.resources.push(resource)
    }
    addMenuOption = (data) => {
        //Looks at whether or not a parent_menu_id is in the data, if so, add it as a dropdown link.
        if(data.parent_menu_id) {
             let parent = this.menu.filter( (m) => Number.parseInt(m.menu_option_id) === Number.parseInt(data.parent_menu_id))[0]
             if(!parent) {
                 console.log("ASTEROID ERROR- Parent not found")
             } else {
                parent.addLinkAsDropdown(data, this)
             }
        } else {
            let menuItem = new MenuOption(data, this)
            this.menu.push(menuItem)
        }
    }

}