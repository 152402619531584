import React, { useContext } from 'react'
import { Form } from 'react-bootstrap'

import { FieldContext } from 'contexts/fieldContext'
import { DataConnectionContext } from 'contexts/dataConnectionContext'

const TextAreaField = () => {

    let resourceFieldValue = useContext(FieldContext)
    let dataConnection = useContext(DataConnectionContext)

    let item = dataConnection.data
    let { setData } = dataConnection

    const handleChange = (e) => {
        setData({
            ...item,
            [e.target.name]: e.target.value
        })
    }

    return <Form.Control
        type='text'
        as='textarea'
        rows={5}
        onChange={handleChange}
        name={resourceFieldValue.name()}
        placeholder={resourceFieldValue.name()}
        value={resourceFieldValue.value}
    />

}

export default TextAreaField