import React, { useContext } from 'react'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'


import { noResultsError } from 'templates/active/siteSettings'

import { DataConnectionContext } from 'contexts/dataConnectionContext'
import { ResourceContext } from 'contexts/resourceContext'


import ResourceBlock from '../../blocks/ResourceBlock'

import PageHeader from '../../components/pageHeader'

import PageOptions from './pageOptions'
import ButtonToolbar from './buttonToolbar'

const View = ({ }) => {
    let resource = useContext(ResourceContext)
    let dataConnection = useContext(DataConnectionContext)

    let item = dataConnection.data
    let { viewAction, parentAction, isFeature } = dataConnection

    let cssId = resource.processCustomCSS(viewAction, item)
    
    const renderDisplayBlocks = () => {
        if (Object.keys(item).length === 0 && !isFeature) {
            return <div><hr /><h3>{noResultsError}</h3><hr /></div>
        } else {
            return resource.getDisplayBlocks(item).map((db) => <ResourceBlock resourceDisplayBlock={db} />)
        }
    }

    return <div className={`${cssId} view-item button-toolbar-parent`}>
        <ButtonToolbar />
        <PageHeader />

        {
            parentAction === 'index' && (!resource.getOption('indexListLink') || resource.getOption('indexListLink') === 'whole') ?
                <Link to={resource.get('itemLink', item)} className="item-link" style={{ display: "block" }}>
                    {renderDisplayBlocks()}
                </Link> : renderDisplayBlocks()
        }

       <PageOptions />
    </div>

}


export default withRouter(View)

