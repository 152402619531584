import React from 'react'
import {Link} from 'react-router-dom'


import { AppContext } from 'contexts/appContext'


//ELIFIX convert to hooks
class Logout extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    componentDidMount = () => {
        this.context.logout();
    }

    render() {
        return <div className='tpBlackBg'>
            <br /><br />
            <h2>You have successfully logged out.</h2>
            <p><Link to="/">Return Home</Link></p>
        </div>
    }
}

Logout.contextType = AppContext
export default Logout
