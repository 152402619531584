import React, { useContext } from 'react'
import ViewResourceFeature from './resourceFeature'

import {DataConnectionContext} from 'contexts/dataConnectionContext'
import {ResourceContext} from 'contexts/resourceContext'


const FeatureBlock = ({ resourceDisplayBlock }) => {
    let info = resourceDisplayBlock.info

    let resource = useContext(ResourceContext)
    let dataConnection = useContext(DataConnectionContext)

    let item = dataConnection.data
    let {action, parentAction, loadPage, update} = dataConnection

    
    if (info[1].resourceFeature.checkPermission(parentAction, {}, item[resource.special_fields.owner_id_field])) {
        let key = `${resource.names.friendly}-${item[resource.get('idField')]}`


        return <ViewResourceFeature
            key={key}
            feature={info}
            originalResource={resource}
            loadPage={loadPage}
            update={update}
            action='index' />


    } else {
        return ""
    }
}

export default FeatureBlock