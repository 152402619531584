import React from 'react';


class Sort extends React.Component {
    constructor(props) {
        super(props);

        this.stateComponent = props.stateComponent
        this.loader = props.loader

        this.state = {}
    }

    handleSearch = (e) => {
        const value = e.target.value
        this.loader.handleParameter('sort', value)
    }

    handleOrder = (e) => {
        const value = e.target.value
        this.loader.handleParameter('sortdir', value)
    }

    clearSearch = (e) => {
        this.loader.clearParameter( 'sort' )
        this.loader.clearParameter( 'sortdir' )
    }


    render() {
        //Get the value of the two fields, the possible values of sort, and see if they've changed (to trigger the clear all)
        let sortValue = this.loader.getParam('sort')
        let options = this.loader.parseFieldsToArray('sortFields')
        let sortdirValue = this.loader.getParam('sortdir')
        let changed = this.loader.checkChanged('sort') || this.loader.checkChanged('sortdir')


        return <span className="asteroid-sort-box">
            <select onChange={this.handleSearch} value={sortValue} >
                <option value="-1"></option>
                {options.map(o =>
                    <option value={o}>{this.loader.printFieldOptionName(o)}</option>
                )}
            </select>
            <div style={{width: '10px', display: 'inline-block'}}></div>
            <select onChange={this.handleOrder} value={sortdirValue} >
                <option value={'ASC'}>Asc.</option>
                <option value={'DESC'}>Desc.</option>
            </select>
            
            {changed ? <span class="format-link small-link" style={{position:'absolute'}} onClick={this.clearSearch}>x</span> : ""}
        </span>
    }
}


export default Sort;
