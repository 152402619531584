import React, { useContext, useState } from 'react'
import { Form } from 'react-bootstrap'

import { FieldContext } from 'contexts/fieldContext'
import { DataConnectionContext } from 'contexts/dataConnectionContext'

const DateFieldMySql = () => {

    let resourceFieldValue = useContext(FieldContext)
    let dataConnection = useContext(DataConnectionContext)

    let item = dataConnection.data
    let { setData } = dataConnection

    const [jsDate, setJsDate] = useState(resourceFieldValue.value ? new Date(resourceFieldValue.value * 1000) : new Date())

    const handleChange = (e) => {
        setJsDate(jsDate)
        setData({
            ...item,
            [resourceFieldValue.name()]: jsDate / 1000
        })
    }


    const changeDay = (e) => {
        jsDate.setDate(e.target.value)
        handleChange()
    }
    const changeMonth = (e) => {
        let value = Number.parseInt(e.target.value) - 1
        if (value && value >= 0) {
            jsDate.setMonth(value)
            handleChange()
        }
    }
    const changeYear = (e) => {
        jsDate.setFullYear(e.target.value)
        handleChange()
    }
    const changeHour = (e) => {
        jsDate.setHours(e.target.value)
        handleChange()
    }
    const changeMinute = (e) => {
        jsDate.setMinutes(e.target.value)
        handleChange()
    }

    return <div style={{ display: 'flex', alignItems: 'center', maxWidth: '400px' }}>

        <Form.Control
            type='text'
            onChange={changeMonth}
            placeholder={'Month'}
            value={jsDate.getMonth() + 1}
        /> /
        <Form.Control
            type='text'
            onChange={changeDay}
            placeholder={'Day'}
            value={jsDate.getDate()}
        /> /
        <Form.Control
            type='text'
            onChange={changeYear}
            placeholder={'Year'}
            value={jsDate.getFullYear()}
        /> &nbsp;
        <Form.Control
            type='text'
            onChange={changeHour}
            placeholder={'Hour'}
            value={jsDate.getHours()}
        /> :
        <Form.Control
            type='text'
            onChange={changeMinute}
            placeholder={'Minute'}
            value={jsDate.getMinutes()}
        />

    </div>

}

export default DateFieldMySql