import React, { useContext } from 'react'
import { withRouter } from 'react-router'


import { DataConnectionContext } from 'contexts/dataConnectionContext'
import { ResourceContext } from 'contexts/resourceContext'

import ViewButton from '../../components/buttons/viewButton'
import EditButton from '../../components/buttons/editButton'

const ButtonToolbar = ({ }) => {

    let resource = useContext(ResourceContext)
    let dataConnection = useContext(DataConnectionContext)

    let { action } = dataConnection


    if (action === 'index') {
        return <div className="button-toolbar">
            {resource.getOption('indexListLink') === 'button' ?
                <ViewButton />
                : ""}


            <EditButton />
        </div>
    } else {
        return ""
    }


}


export default withRouter(ButtonToolbar)

