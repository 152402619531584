import React, {useContext} from 'react'
import { withRouter } from 'react-router'

import DataConnection from '../controllers/dataConnection'

import { DataConnectionContext } from 'contexts/dataConnectionContext'
import { ResourceContext } from 'contexts/resourceContext'

const DisplayResourceFeature = ({ feature }) => {
    let dataConnection = useContext(DataConnectionContext)

    let item = dataConnection.data

    let resourceFeature = feature[1].resourceFeature

    let action;
    switch (resourceFeature.relationship_kind) {
        case "1-1":
            action = 'view'
            break;
        case "1-M":
            action = 'index'
            break;
        case "M-M":
            action = 'index'
            break;
    }

    let cssId = resourceFeature.processCustomCSS('view')
    let sectionClass = resourceFeature.sectionClass()

    let featureInformation = {
        parentResource: resourceFeature.parentResource,
        parentItem: item,
        parent_id: resourceFeature.parentResource.getId(item),
        resourceFeature
    }

    return <div className={`${cssId} feature-item ${sectionClass}`}>

        {resourceFeature.renderTitle(action) ? <h2>{resourceFeature.renderTitle(action)}</h2> : ""}

        <div className="container">
            <ResourceContext.Provider value={resourceFeature.connectedResource} >
                <DataConnection
                    action={action}
                    featureInformation={featureInformation}

                />
            </ResourceContext.Provider>
        </div>

    </div>


}

export default withRouter(DisplayResourceFeature)

