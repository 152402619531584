import React from 'react'

const Phone = ({ value, resourceField, item }) => {
    let parsedNumber = `(${ value.substring(0, 3) })  ${ value.substring(3, 6) } - ${ value.substring(6, 10) }`
    return  <a className={`${resourceField.options.linkClass}`} href={`tel: ${value}`}>
            <span class="link-reg-text">
                { value === resourceField.linkText(value, item) ? parsedNumber : resourceField.linkText(value, item)}
            </span>
            <span class="link-hover-text">{parsedNumber}</span>
        </a>
    
}

export default Phone