import React, { useContext } from 'react'

import DisplayList from './displayList'
import DisplayFeatures from './displayFeatures'
import PageOptions from './pageOptions'

import PageHeader from '../../components/pageHeader'
import Toolbar from '../../components/toolbar/toolbar'

import { ResourceContext } from 'contexts/resourceContext'
import { DataConnectionContext } from 'contexts/dataConnectionContext'


const DefaultIndex = ({}) => {
    let resource = useContext(ResourceContext)
    let dataConnection = useContext(DataConnectionContext)

    let { loader } = dataConnection
    let cssId = resource.processCustomCSS('index')

    return <div className={`${cssId}`}>
        <PageHeader />

        <div className="container">
            <Toolbar />
            <DisplayList />
            {loader.displayPagination()}
            <DisplayFeatures />
        </div>

        <PageOptions />
    </div>
}


export default DefaultIndex
