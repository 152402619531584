import React, {useState, useContext} from 'react'

import { DataConnectionContext } from 'contexts/dataConnectionContext'
import { ResourceContext } from 'contexts/resourceContext'


const DeleteAllButton = () => {

    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)

    let resource = useContext(ResourceContext)
    let dataConnection = useContext(DataConnectionContext)

    let item = dataConnection.data

    let permissionCheck = resource.checkPermission('delete', item)

    const deleteAllLink = async (e) => {
        if (window.confirm("Are you ABSOLUTELY sure you want to delete ALL ENTRIES? There is NO WAY to undo.")) {
            if (window.confirm("LAST CHANCE- You are about to delete ALL RECORDS. Do you wish to destroy all your progress?")) {
                setLoading(true)
                let res = await resource.beDeleteAllCall()
                if (res) {
                    setLoading(false)
                    setSuccess(true)
                }
            }
        }
    }

    if (permissionCheck) {
        return <span className="format-link" onClick={ deleteAllLink }>
            { loading ? "LOADING..." : ( success ? "SUCCESS" : "Delete All Entries")}
        </span>
    } else { return "" }

}

export default DeleteAllButton