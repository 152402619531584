
import { apiPathLink } from 'templates/active/siteSettings'

import jwt from 'jsonwebtoken'
import User from 'asteroid/classes/user/user'

export const apiPath = (path) => { return `${apiPathLink}${path}` }

//Component paths
export const userPath = (path = "") => { return apiPath(`/users${path}`) }
export const authPath = (path = "") => { return apiPath(`/auth${path}`) }
export const adminPath = (path = "") => { return userPath(`/admin${path}`) }

//Auth Paths
export const checkUserPath = () => { return authPath(`/check`) }
export const registerPath = () => { return authPath(`/register`) }
export const verifyUserPath = (username, hash) => { return authPath(`/verify/${username}/${hash}`) }
export const loginPath = () => { return authPath(`/login`) }
export const forgotPasswordPath = (username) => { return authPath(`/forgottenPassword/${username}`) }
export const resetPasswordPath = (username, hash) => { return authPath(`/resetPassword/${username}/${hash}`) }

export const expireTokenCheck = () => {
    //Decode the local token
    var decodedToken = jwt.decode(localStorage.token, { complete: true });
    
    var dateNow = new Date();
    //JWT stored in seconds, not milliseconds for some reason, so x 1000
    var expirationDate = new Date(decodedToken.payload.exp * 1000)
    
    if (expirationDate.getTime() < dateNow.getTime()) { return true }
    else {return false}
}

let userData = localStorage.user ? JSON.parse(localStorage.user) : false
if(userData) { userData = new User( userData ) }
export const curr_user = userData

export const headers = { headers: { 'authorization': localStorage.token } }
export const token = localStorage.token


export const logoutCookies = () => {
    localStorage.setItem('user', null);
    localStorage.setItem('token', null);
}
export const loginCookies = (user, token) => {
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('token', token);
}




export default {
    apiPath,
    userPath,
    authPath,
    adminPath,
    checkUserPath,
    registerPath,
    verifyUserPath,
    loginPath,
    forgotPasswordPath,
    resetPasswordPath,
    loginCookies,
    logoutCookies
}