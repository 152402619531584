import React, {useContext, useState} from 'react';
import { Link } from 'react-router-dom'
import Modal from 'asteroid/components/ui/modal/modal'

import DataConnection from '../../controllers/dataConnection';


import { ResourceContext } from 'contexts/resourceContext'
import { DataConnectionContext } from 'contexts/dataConnectionContext'



const NewButton = ({}) => {

    const [closed, setClosed] = useState(false)

    let resource = useContext(ResourceContext)
    let dataConnection = useContext(DataConnectionContext)

    let item = dataConnection.data
    let { action, update, loadPage } = dataConnection

    // const loadPage = (update) => {
    //     if (update) { setClosed(true) }
    //     //this.props.loadPage(update)
    // }


    let permissionCheck = resource.checkPermission('new')

    if (permissionCheck) {
        if (!resource.getOption('indexListNew') || resource.getOption('indexListNew') === 'page') {
            return <Link to={`${resource.get("urlPath")}/new`} className="new-link format-link">
                New <span className="fas fa-plus"></span>
            </Link>
        } else {
            return <span className="new-link">
                <Modal button={<span className="fas fa-plus"></span>}
                    title={"New"}
                    closeTrigger={closed}
                    body={<DataConnection action={'new'} />}
                    smallLink={true} />
            </span>
        }
    }
    else { return "" }
}

export default NewButton
