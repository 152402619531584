import React, {useState} from 'react'
import Menu from './menu/Menu.js'
import ProfileSelect from '../../asteroid/components/auth/profileSelect'
import { withRouter } from 'react-router'
import { NavLink } from 'react-router-dom'



import { logoURL }  from 'templates/active/siteSettings'


const Header = () => {

    //openDropdown holds the id of the currently open dropdown (only allow one open at a time)
    const [openDropdown, setOpenDropdown] = useState(0)

    //This code is closes the menu when the user clicks somewhere besides it.
    document.addEventListener('mousedown', (e) => {
        let parent = e.target.closest('#header')
        if (!parent) { setOpenDropdown(0) }
    })

    return <header id="header">

        <header className="header-top-line">
            {/* Allows the user to select an alternate profile. */}
            <ProfileSelect />
            <Menu openDropdown={openDropdown} setOpenDropdown={setOpenDropdown} menu="topMenu" />
        </header>

        <header className="header-main-line">
            <NavLink to="/" id="main-logo-link">
                <img id="main-logo" alt="logo" src={logoURL} />
            </NavLink>

            <Menu openDropdown={openDropdown} setOpenDropdown={setOpenDropdown} menu="menu" />
        </header>

    </header>

}

export default withRouter(Header);

