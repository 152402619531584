import React from "react"
import Search from 'asteroid/components/asteroidResource/components/toolbar/search/search'
import Filter from 'asteroid/components/asteroidResource/components/toolbar/filter/filter'
import Sort from 'asteroid/components/asteroidResource/components/toolbar/sort/sort'
import Pagination from 'asteroid/components/asteroidResource/components/toolbar/paginate/pagination'

export default class Loader {

    constructor(resource, url = true) {
        this.url = url

        //Helpful class that lets you set/get url search params and print them out
        const urlParamObject = new URLSearchParams( url ? window.location.search : '')

        //For references back to the resource
        this.resource = resource

        //Combine the incoming defaults from the resource to the fields in the url.
        Object.entries(resource.loader).map(incomingDefault => {
            let inValue = urlParamObject.get(incomingDefault[0])
            if (!inValue) {
                urlParamObject.set(incomingDefault[0], incomingDefault[1])
            }
            return ""
        })
        
        let page = urlParamObject.get('page')
        if (!page) {
            urlParamObject.set('page', 1)
        }

        this.urlParamObject = urlParamObject
        this.pager = {}

        this.go()
    }


    static fromFeature(resource, featureInformation) {
        let nLoader;
        if(featureInformation?.resourceFeature?.resource_feature_id && featureInformation.parent_id) {
            nLoader = new Loader(resource, false)
            nLoader.updateParam('resourceFeature', featureInformation.resourceFeature.resource_feature_id)
            nLoader.updateParam('parent_id', featureInformation.parent_id)
        } else {
            nLoader = new Loader(resource, true);
        }
        return nLoader
    }

    go = () => {
        if(this.url){
            window.history.replaceState({}, "", window.location.pathname + '?' + this.urlParamObject.toString())
        }
    }

    getParam = (name) => {
        return this.urlParamObject.get(name) || ""
    }

    //Returns the default value set in the resource
    getDefaultParam = (name) => {
        return this.resource.loader[name] || ""
    }

    //Sets the param in the urlParamObject and applies that change to the URL 
    updateParam = (param, value) => {
        this.urlParamObject.set('page', 1)
        this.urlParamObject.set(param, value)
        this.go()
    }
    //Deletes the param in the urlParamObject and applies that change to the URL 
    deleteParam = (param) => {
        this.urlParamObject.set('page', 1)
        this.urlParamObject.delete(param)
        this.go()
    }

    //Takes in the stateComponent, and the param&value to update the state. Just a helper function.
    handleParameter = (param, value) => {
        this.updateParam(param, value)
    }

    handleDeleteParameter = (param) => {
        this.deleteParam(param)        
    }

    clearParameter = (setLoader, param) => {
        //If there's a default, reset it to that.
        let defaultValue = this.getDefaultParam(param)
        if (defaultValue) {
            this.handleParameter(setLoader, param, defaultValue)
        }
        //otherwise delete it
        else {
            this.handleDeleteParameter(setLoader, param)
        }
    }

    checkChanged = (field) => {
        let currentValue = this.getParam(field)
        let defaultValue = this.getDefaultParam(field)
        let ret = currentValue !== defaultValue


        if(field ==='page' && currentValue == 1) { return false }
        return ret
    }
    
    checkSortSearchFilterOptions = () => {
        return this.resource.getOption('search') || 
        this.resource.getOption('filter') || 
        this.resource.getOption('sort')
    }

    resetAllParams = (setLoader) => {
        let paramsToReset = ['page', 'filter', 'sort', 'sortdir', 'search']

        paramsToReset.forEach((param) => {
            if (this.checkChanged(param)) {
                this.clearParameter(setLoader, param)
            }
        })
    }

    printFieldOptionName = (fieldName) => {
        let resourceField = this.resource.getField(fieldName)
        return resourceField.displayName || fieldName
    }



    //=======================================
    //  DISPLAY FUNTIONS
    //=======================================

    //Display options for search, filter, and sort.
    displaySearch = () => {
        const hasSearch = this.resource.getOption('search')

        if (hasSearch) {
            return <Search loader={this} />
        }
    }

    displayFilter = () => {
        const hasFilter = this.resource.getOption('filter')
        if (hasFilter) {
            return <Filter loader={this} />
        }
    }

    displaySort = () => {
        const hasSort = this.resource.getOption('sort')

        if (hasSort) {
            return <Sort loader={this} />
        }
    }

    displayPagination = () => {
        const hasSort = this.resource.getOption('paginate')

        if (hasSort) {
            return <Pagination loader={this} />
        }
    }

    //In resource options, the fields to search/sort/filter are stored as a string with the format: "var1","var2","var3" 
    //So cut the first & last parenthesis, and split by ' "," ' to get an array of field names
    parseFieldsToArray = (option) => {
        let text = this.resource.getOption(option)
        return text = text.substring(1, text.length - 1).split('","')
    }

    //Filter options are stored... badly at the moment. They are a string, in format field1:value1;field2:value2;field3:value3
    //This breaks up that string and returns an array of {field, value} objects that are being applied
    parseCurrentFilterSelection = () => {
        let existingValues = this.getParam('filter')

        if (existingValues) {
            existingValues = existingValues.split(';')
            existingValues = existingValues.map((v) => {
                let pair = v.split(':')
                if (pair[1]) {
                    return { field: pair[0], value: pair[1] }
                }
                return null
            })
            return existingValues
        } else {
            return []
        }
    }


    

    //This function takes in a filter field & value, 
    //changes that in the current filter settings, creates a new url string, 
    //and calls the handleParameter function with the passed in stateComponent to apply the filter
    handleFilterOption = (field, value) => {
        let existingValue = this.filterOptionValue(field)
        let existingValues = this.parseCurrentFilterSelection()

        if (existingValue) {
            existingValues = this.setFilterOptionValue(field, value)
        } else {
            existingValues.push({ field: field, value: value })
        }

        let newString = this.stringifyFilterOptions(existingValues)
        
        this.handleParameter('filter', newString)
    }

    //This function, similar to above, takes in a field, but clears that value, and applies the update.
    handleRemoveFilterOption = (field) => {
        let existingValues = this.parseCurrentFilterSelection()

        existingValues = existingValues.map((ev) => {
            if (ev.field === field) {
                return null
            }
            return ev
        }).filter((i) => i !== null)

        let newString = this.stringifyFilterOptions(existingValues)
        this.handleParameter('filter', newString)
    }

    //Returns the value of a field if it's in the current filter selection
    filterOptionValue = (field) => {
        let ret = this.parseCurrentFilterSelection().filter((v) => v && v.field === field)[0]
        return ret?.value
    }

    //Returns a new {field, value} pair updating the current selection with a new field/value pair.
    setFilterOptionValue = (field, value) => {
        return this.parseCurrentFilterSelection().map((ev) => {
            if (ev.field === field) {
                ev.value = value
            }
            return ev
        })
    }

    //Takes in the {field, value} pairs created/edited above and returns it to an array.
    stringifyFilterOptions = (values) => {
        return values.map((eV) => eV ? `${eV.field}:${eV.value}` : null).filter((f) => f !== null).join(';')
    }

    printSummary = (setLoader) => {
        let textStatement = [this.printField('page'), this.printField('search'), this.printSort(), this.printField('filter', setLoader)]
        textStatement = textStatement.filter((t) => !!t)

        if (textStatement.length > 0) {
            return <div className="loader-summary-bar">
                <i>You are {this.printTextList(textStatement)}.</i>

                <span className="format-link small-link" style={{marginBottom: '0'}} onClick={() => { this.resetAllParams(setLoader) }}>Clear All</span>
            </div>

        }
    }

    printField = (field) => {
        let text = this.getParam(field)

        switch (field) {
            case "search":
                text = `"${text}"`
                break;
            case "filter":
                text = this.parseCurrentFilterSelection().map(({ field, value }) => {
                    let resourceField = this.resource.getField(field)

                    if(!resourceField) { console.log( "ERR!", "Cannot find ", field, " on ", this.resource)}

                    if (resourceField?.fieldType === 'resource_select') {
                        value = resourceField.getResourceSelectInfo(value, this.resource)
                    }
                        
                    return <>{this.printFieldOptionName(field)}: {value}</>
                    

                }).filter(t => t) 

                text = <>{this.printTextList(text)}</>

                break;
            default:
                break;
        }

        if (this.checkChanged(field)) {
            return <>{this.printTerminology(field)} {text}</>
        }
    }

    //separate function for sort because it includes sortdir too
    printSort = () => {
        if (this.checkChanged('sort') || this.checkChanged('sortdir')) {
            let text = this.printTerminology('sort')
            let sort = this.printFieldOptionName(this.getParam('sort'))
            let sortdir = this.getParam('sortdir') === "ASC" ? "Ascending" : "Descending"

            return `${text} ${sort} (${sortdir})`
        }
    }

    printTerminology = (field) => {
        return {
            page: "viewing page",
            search: "searching for",
            sort: "sorting by",
            filter: "using the filters;"
        }[field]
    }


    printTextList = (array) => {
        if (array.length === 2) {
            array.splice(1, 0, " and ");
        }
        else if (array.length > 2) {
            array = [].concat(...array.map(e => [e, ', ']))
            array.pop()
            array[array.length-2] = ", and "
        }
        return array
    }

}