import React, { useContext } from 'react'
import { Form } from 'react-bootstrap'

import { FieldContext } from 'contexts/fieldContext'
import { DataConnectionContext } from 'contexts/dataConnectionContext'

const IconFormField = () => {

    let resourceFieldValue = useContext(FieldContext)
    let dataConnection = useContext(DataConnectionContext)

    let item = dataConnection.data
    let { setData } = dataConnection

    const handleChange = (e) => {
        setData({
            ...item,
            [e.target.name]: e.target.value
        })
    }

    return <>
        <Form.Control
            type='text'
            onChange={handleChange}
            name={resourceFieldValue.name()}
            placeholder={resourceFieldValue.name()}
            value={resourceFieldValue.value}
        />
        <span className={`fas fa-${resourceFieldValue.value}`}></span>
    </>
}

export default IconFormField;
