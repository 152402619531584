import React, {useContext} from 'react'
import FormStructure from './defaultFormLayout'
import { withRouter } from "react-router-dom";

import { ResourceContext } from 'contexts/resourceContext'
import { DataConnectionContext } from 'contexts/dataConnectionContext'


const Form = () => {
    
    let resource = useContext(ResourceContext)
    let dataConnection = useContext(DataConnectionContext)

    let item = dataConnection.data
    let {setData, loadPage, action} = dataConnection


    const submitApiCall = async () => {
        let apiCall = null;

        if (action === 'edit') { apiCall = await resource.beEditCall(item) } 
        else { apiCall = await resource.beNewCall(item) }

        if (!(apiCall.status === 400 || apiCall.status === 500)) {
            if (resource.options.blockRedirect) { await setData(apiCall) }
            else { await loadPage() }
        }

        return apiCall
    }


    const deleteApiCall = (e) => {
        e.preventDefault()
        if (!resource.get('deleteConfirm') || window.confirm(resource.get('deleteWarning'))) {
            resource.beDeleteCall(item)
                .then(res => {
                    let redirect = resource.getRedirect('delete', item)
                    if (redirect) {
                        ///history.push(redirect)
                    } else {
                        loadPage()
                    }
                })
                .catch(err => console.log(err))
        }
    }


    return <div className='full-form'>
        <FormStructure
            submitApiCall={submitApiCall}
            deleteApiCall={deleteApiCall}
        />
    </div>

}


export default withRouter(Form)