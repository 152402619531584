import React, { } from 'react'

import NewButton from '../../components/buttons/newButton'
import ResourcePanelButton from '../../components/buttons/resourcePanellButton'
import DeleteAllButton from '../../components/buttons/deleteAllButton'



const PageOptions = () => {
    if (true) {
        return <div className="page-options">
            <div>
                <div className="container">
                    <NewButton />
                    <ResourcePanelButton />
                    <DeleteAllButton />
                </div>
            </div>
        </div>
    }
}

export default PageOptions