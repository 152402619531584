export default class MenuOption {
    constructor(data, site) {
        this.menu_option_id = data.menu_option_id || 0
        this.name = data.displayText

        this.symbol = data.symbol
        this.order = data.order

        this.parent_menu_id = data.parent_menu_id

        this.links = []

        //There are two ways to build the link.
        //You can provide a direct link.
        if (data.link) {
            this.link = data.link
        }
        //Or you can provide a resource/action and it builds it.
        else if (data.parent_resource_id && data.action) {
            this.link = "/" + site.findResourceById (data.parent_resource_id).get('friendly') + this.parseAction(data.action)
        }

        this.permissions = site.getPermissionById(data.permission_id || 1)
    }


    parseAction = (action) => {
        switch (action) {
            case 'index':
                return ''
            case 'new':
                return '/new'
            default: 
                console.log("Error in menu option- invalid action", action)
        }
    }

    isDropdown = () => {
        return !this.link
    }

    addLinkAsDropdown = (data, site) => {
        if (this.isDropdown()) {
            let newLink = new MenuOption(data, site)
            this.links.push(newLink)
            return newLink
        }
    }

    getLinks = () => {
        return this.links.sort((a, b) => { return a.order - b.order })
    }

}