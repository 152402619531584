import React, { useContext } from 'react'
import { Form } from 'react-bootstrap'

import { FieldContext } from 'contexts/fieldContext'
import { DataConnectionContext } from 'contexts/dataConnectionContext'

const ArrayField = () => {

  let resourceFieldValue = useContext(FieldContext)
  let dataConnection = useContext(DataConnectionContext)

  let item = dataConnection.data
  let { setData } = dataConnection



  const handleArrayChange = (e) => {
    const set = e.target.name.split('-')
    const field = set[0]
    const index = set[1]
    const value = e.target.value
    const array = item[field] || []
    if (value === "") {
      array.splice(index, 1)
    } else if (value.indexOf(';') > -1) {
      array[index] = value.split(';')[0]
      array[array.length] = ""
    } else {
      array[index] = value
    }
    setData({
      ...item,
      [field]: array
    })
  }

  const field = () => {
    if (item[resourceFieldValue.resourceField.fieldName] && item[resourceFieldValue.resourceField.fieldName].length > 0) {
      return item[resourceFieldValue.resourceField.fieldName].map((i, index) => <div key={index}>
        <Form.Control
          onChange={handleArrayChange}
          type={'text'}
          value={i}
          name={`${resourceFieldValue.resourceField.fieldName}-${index}`} />
        <br />
      </div>)
    } else {
      return <Form.Control onChange={handleArrayChange} type='text' placeholder="Start typing" name={`${resourceFieldValue.resourceField.fieldName}-${0}`} />
    }
  }



  return <div>
    {field()}
    <i>Hit ";" (semicolon) to add a new field, backspace a line to delete one.</i>
  </div>

}

export default ArrayField
