import React from 'react'

//ELIFIX convert to hooks
class Modal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false
        }

    }

    componentWillReceiveProps = (props) => {
        if(props.closeTrigger && !this.props.closeTrigger) {
            this.setState({open: false})
        }
    }

    toggle = () => {
        this.setState({ open: !this.state.open })
    }

    backgroundToggle = (e) => {
        if(e.target === e.currentTarget) {
            this.toggle()
        }
    }

    render() {

        return <>

            <div className={`modal-button format-link ${this.props.smallLink ? " small-link" : ""}`} onClick={this.toggle}>
                {this.props.button}
            </div>

            {this.state.open ?
                <div className="modal-far-bg" onMouseDown={this.backgroundToggle}>
                    <div className="modal-body container">

                        <h3>{this.props.title} <span className="modal-close fas fa-times" onClick={this.toggle}></span> </h3>
                        

                        {this.props.body}

                    </div>
                </div>
                : ""}

        </>

    }

}

export default Modal