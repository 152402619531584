import React, {useContext, useState} from 'react';


import { DataConnectionContext } from 'contexts/dataConnectionContext'
import { ResourceContext } from 'contexts/resourceContext'


const DuplicateButton = () => {

    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)

    let resource = useContext(ResourceContext)
    let dataConnection = useContext(DataConnectionContext)

    let item = dataConnection.data


    const duplicateLink = async (e) => {
        setLoading(true)
        let res = await resource.beDuplicateCall(item)
        if (res) {
            let url = resource.feEditPath(res.data)
            window.location.href = url
            setLoading(false)
            setSuccess(true)
        }
    }

    let permissionCheck = resource.checkPermission('new', item)
    if (permissionCheck) {
        return <span className="small-link format-link" onClick={duplicateLink}>

            {loading ? "LOADING..." : (success ? "SUCCESS" : "Duplicate")}

        </span>
    } else { return "" }


}

export default DuplicateButton