import React from 'react'
import { withRouter, Link } from 'react-router-dom'

const MenuLink = ({ menuOption }) => {
    if (menuOption.permissions?.checkPermission('view')) {
        return <Link key={menuOption.name} className={`hmenu-item`} to={menuOption.link || '/'}>
            <span className={`fas fa-${menuOption.symbol}`}></span>

            <span>{menuOption.name}</span>
        </Link>
    } else {
        return ""
    }
}

export default withRouter(MenuLink);
