import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { withRouter } from 'react-router';

import Home from 'templates/active/home';

import FourOhFour from './404Component'

import CoreComponent from '../../asteroid/components/controller';
import DefaultComponent from '../../asteroid/components/asteroidResource/controllers/controller';


/* 

THIS IS THE MAIN LOGIC FILE 
This is the page that ends up parsing the URL and figuring out where to go
Most traffic will end up going to the default component, unless you override here. 
The home page has it's own route / page

*/


function Body() {
	return (
		<div className="body">
			<div className="page-container">
				<Switch>
					{/* HOME PAGE */}
					<Route path="/" exact>
						<Home />
					</Route>

					{/* CUSTOM ROUTES CAN GO BELOW HERE*/}

					{/* LOGIN / LOGOUT / PASSWORD / SIGN UP */}
					<Route path="/auth">
						<CoreComponent />
					</Route>

					{/* Default controller for auto handling each component */}
					<Route path="/:urlPath">
						<DefaultComponent />
					</Route>

					<Route path="/" component={FourOhFour} />
				</Switch>
			</div>
		</div>
	);
}

export default withRouter(Body);
