import React, {useContext} from 'react'
import { withRouter } from "react-router-dom";

import { ResourceContext } from 'contexts/resourceContext'
import { FieldContext } from 'contexts/fieldContext'

import { Form } from 'react-bootstrap'
import FieldType from './fieldTypes'

var isCtrl = false

const FormFields = () => {
    
    let resource = useContext(ResourceContext)
    let {resourceField} = useContext(FieldContext)

    const checkCtrlSSave = (e) => {
        if (e.keyCode == 17) {
            e.preventDefault();
            isCtrl = true;
        }
        if (e.keyCode == 83 && isCtrl) {
            e.preventDefault();
            this.props.submitForm(e);
        }
    }

    const removeCtrl = () => {
        isCtrl = false
    }


    return <Form.Group onKeyDown={checkCtrlSSave} onKeyUp={removeCtrl} >

        <Form.Label>
            <b>{resourceField.printifyName(resource)}</b><br />
            { /* Add any notes for any resourceFieldValues here */}
            <i>{resourceField.text?.formText}</i>
        </Form.Label>

        <FieldType />


        {/* TODOFIX form validations - {resourceFieldValue.resourceField.validations ? resourceFieldValue.resourceField.validations.map(val => <div style={{color:'red'}}>
                    {val === 'required' ? (resourceFieldValue.value === undefined || resourceFieldValue.value === null || resourceFieldValue.value === "" ? "Field is required." : "") : ""}
                </div>) : ""} */}

    </Form.Group>

}

export default withRouter(FormFields)

















