import React, {useContext} from 'react';
import { Link } from 'react-router-dom'

import { ResourceContext } from 'contexts/resourceContext'


function ResourcePanelButton({}) {
    let resource = useContext(ResourceContext)

    //TODOFIX- check permissions on resource itself
    let permissionCheck = resource.checkPermission('new')
    if (permissionCheck) {
        if(resource.base_name !== 'resource') {
            return <Link className="format-link" to={`/resources/${resource.resource_id}`}>Resouce Panel</Link>
        } else { return "" }
    } else { return "" }
}

export default ResourcePanelButton