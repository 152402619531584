import React from 'react'

import resourceHelper from './resource_helper'

import ResourceField from './resourceField'
import ResourceFieldValue from './resourceFieldValue'
import ResourceFeature from './resourceFeature'
import ResourceDisplayBlock from './resourceDisplayBlock'
import ResourceCategory from './resourceCategory'

import api, { headers } from 'helpers/api'
import axios from 'axios'

/* 

This class controls a "Resource"- a REST class

It takes in a baseName and generates all necessary information-

NAMES
example - generates examples, Example, Examples, examples, and /examples

PERMISSIONS
Controls who can see which pages

FIELDS
Important fields- generates example_id, example_name, and example_tags

REERENCES
Fields that connect with another record

FEATURES
Turn on & off common features like sorting, search, pagination, etc.

TEXT
Control which text is shown when on the default views.

LOADER
The loader pretty much sets what "default" options are set when you first go a page (filter, sort, etc.)

*/



export default class Resource {

    constructor(data, site) {
        //Finds the permission from the incoming site and stores it here.
        this.permissions = site.getPermissionById(data.permission_id || 1)
        //Set the id.
        this.resource_id = data.resource_id
        //Directly copy over all of the incoming data.
        Object.entries(data).map(obj => this[obj[0]] = obj[1])


        this.text = data.text || {}
        this.actionRedirects = data.actionRedirects || {}

    }

    init = (data, site) => {
        //Map over and add the fieldList.
        this.fields.fieldList = data.fields.list.map(f => new ResourceField(f, site))
        this.categories = data.categories.map(c => new ResourceCategory(c, site))
        this.features = data.features.map(f => new ResourceFeature(f, site))
    }

    getId = (item) => {
        return item ? item[this.get('idField')] : undefined
    }
    getField = (fieldName) => {
        let ret = null
        this.fields.fieldList.map((i) => i.fieldName === fieldName ? ret = i : null)
        return ret
    }

    getDefaultFields = () => {
        return this.fields.fieldList.map((fl) => new ResourceFieldValue(fl))
    }

    //Returns a JSON object of name/value pairs for a "new" item
    getDefaultItem = () => {
        let obj = {}
        this.getDefaultFields().map(resourceFieldValue => obj[resourceFieldValue.name()] = resourceFieldValue.value)
        return obj
    }
    getFeature = (name) => {
        let retFeature = null
        this.features.map(f => f.name === name ? retFeature = f : null)
        return retFeature
    }
    getOption = (name) => {
        if (this.options[name]) {
            return this.options[name]
        } else {
            return this.actionOptions[name]
        }
    }
    //Takes in an json item (with just name/values) and returns the resourceFields for that item
    getResourceFieldValues = (item) => {
        let returnResourceFieldValues = []

        this.getDefaultFields().map(resourceFieldValue => {
            let itemValue = item[resourceFieldValue.name()]
            if (itemValue) { resourceFieldValue.setValue(itemValue) }
            returnResourceFieldValues.push(resourceFieldValue)
            return null
        })

        return returnResourceFieldValues.sort((a, b) => {
            let aC = a.resourceField.resource_category_id
            let bC = b.resourceField.resource_category_id

            if (aC) { aC = this.categories.filter((c) => c.resource_category_id === aC)[0].order } else { aC = 100 }
            if (bC) { bC = this.categories.filter((c) => c.resource_category_id === bC)[0].order } else { bC = 100 }

            let categoryMatch = aC - bC
            if (categoryMatch === 0) { return a.resourceField.order - b.resourceField.order }
            else { return categoryMatch }
        })
    }



    getFieldDisplayBlocks = (item) => {
        let resourceFieldValues = this.getResourceFieldValues(item)

        let categories = []
        let fields = []
        //Figure out which fields have categories & not and store them in the respective above arrays.
        resourceFieldValues.map(resourceFieldValue => {
            if (resourceFieldValue.resourceField.resource_category_id) {
                categories.push(resourceFieldValue)
            } else {
                fields.push(resourceFieldValue)
            }
            return ""
        })

        //For the fields, map over it and create the display blocks.
        let fieldBlocks = fields.map(resourceFieldValue => {
            let order = resourceFieldValue.resourceField.order
            let info = resourceFieldValue
            let kind = 'field'

            return new ResourceDisplayBlock({ order, info, kind })
        })

        let tempCats = {}

        //Go through the categories and sort them out into each differnt array.
        categories.map(resourceFieldValue => {
            let cId = resourceFieldValue.resourceField.resource_category_id

            if (tempCats[cId]) {
                tempCats[cId].push(resourceFieldValue)
            } else {
                tempCats[cId] = [resourceFieldValue]
            }

            return ""
        })

        //Get the category information and create the  display block.
        let categoryBlocks = Object.entries(tempCats)
            .map((pair) => {
                let resource_category_id = Number.parseInt(pair[0])
                let resourceFieldValues = pair[1]
                let category = this.categories.filter((c) => Number.parseInt(c.resource_category_id) === Number.parseInt(resource_category_id))[0]

                let cbdata = {
                    order: category.order,
                    info: { category, resourceFieldValues },
                    kind: 'category',
                }

                return new ResourceDisplayBlock(cbdata)
            })

        return [...fieldBlocks, ...categoryBlocks]
    }

    getFeatureDisplayBlocks = (item) => {
        let features = item.features || {}

        return Object.entries(features)
            .map(i => {
                let order = i[1].resourceFeature.order
                let info = i
                let kind = 'feature'
                let resource_feature_id = info[1].resourceFeature.resource_feature_id
                let resourceFeature = this.features.filter((f) => f.resource_feature_id === resource_feature_id)[0]
                info[1].resourceFeature = resourceFeature

                let rdb = new ResourceDisplayBlock({ order, info, kind })
                return rdb
            })
    }

    getDisplayBlocks = (item) => {
        let resourceFeatures = this.getFeatureDisplayBlocks(item)

        let resourceFields = this.getFieldDisplayBlocks(item)

        let pageBlocks = [...resourceFeatures, ...resourceFields]

        return pageBlocks.sort((a, b) => (a.order || 100) - (b.order || 100))
    }


    getRedirect = (action, item) => {

        let ret = null

        let blockLink = this.actionRedirects[`${action}BlockLink`]
        let customRedirect = this.actionRedirects[`${action}RedirectLink`]

        if (!blockLink) {
            if (customRedirect) {
                ret = this.safeProcess(customRedirect, item)
            } else {
                switch (action) {
                    case "new":
                    case "edit":
                        return this.feViewPath(item)
                    case "delete":
                        return this.get('urlPath')
                    default:
                        console.log("Error in classes/resource invalid action", action)
                }
            }
        }
        console.log(ret)
        return ret

    }

    //This function allows you to pass in plain text with fields in brackets which replaces with the correct value.
    //Example: value = "{first_name}'s Profile" => "Jordan's Profile"
    safeProcess = (value, item) => {
        if (!item) { throw new Error("You need to pass in an item.") }
        let arr = value.split('{')
        let arr2 = arr.map(segment => {
            let split = segment.split('}')
            if (split[1] !== undefined) {
                split[0] = item[split[0]]
            }
            return split.join("")
        })
        return arr2.join("")
    }

    addMenuOption = (options) => { this.menuOptions.push(options) }

    checkPermission = (view, item = {}, asteroidSite) => {

        let permissionsToCheck = this.permissions

        if (this.special_fields?.statusOptions && this.special_fields?.statusField) {

            if (item[this.idField] && asteroidSite) {

                let status = item[this.special_fields.statusField]

                let statusOptionObject = JSON.parse(this.special_fields.statusOptions)

                let permissionId = statusOptionObject.filter((o) => o.name === status)[0]

                permissionId = permissionId ? permissionId.permission_id : 1

                permissionsToCheck = asteroidSite.getPermissionById(permissionId)
            }

        }

        let ownerId = item && this.special_fields.owner_id_field ? item[this.special_fields.owner_id_field] : null

        return permissionsToCheck.checkPermission(view, item, ownerId)


    }

    checkCustomDisplay = (action) => {
        return this[`${action}HTML`]
    }

    customDisplay = (action, data) => {
        let rawInfo, item;
        switch (action) {
            case "view":
                rawInfo = this[`viewHTML`]
                item = this.replaceFieldsWithValues(data, rawInfo)
                return <div dangerouslySetInnerHTML={{ __html: item }} />
            case "indexList":
                rawInfo = this[`indexListHTML`]
                item = this.replaceFieldsWithValues(data, rawInfo)
                return <div dangerouslySetInnerHTML={{ __html: item }} />
            case "index":
                rawInfo = this[`indexHTML`]
                let rawItemInfo = this[`indexListHTML`]
                let items = data.map((item) => this.replaceFieldsWithValues(item, rawItemInfo)).join("")
                let ret = rawInfo.replace("{items}", items)
                return <div dangerouslySetInnerHTML={{ __html: ret }} />
        }
    }

    replaceFieldsWithValues = (item, rawInfo) => {
        rawInfo = rawInfo.split('{')
        if (rawInfo.length > 1) {
            for (var i = 1; i < rawInfo.length; i++) {
                let pair = rawInfo[i].split('}')
                let varName = pair[0]
                varName = item[pair[0]]
                rawInfo[i] = varName + pair[1]
            }
        }
        rawInfo = rawInfo.join("")
        return rawInfo
    }

    //------------------------------
    // PATHS & URLS 
    //------------------------------
    urlPath = () => { return this.get('urlPath') }
    idPath = (item) => { return this.urlPath() + `/${item[this.get('idField')]}` }
    beUrlPath = () => { return api.apiPath(this.urlPath()) }
    beIdPath = (item) => { return api.apiPath(this.idPath(item)) }
    beUniqueFieldValuesPath = (field) => { return api.apiPath(this.urlPath() + `/values/${field}`) }
    beDuplicatePath = (item) => { return api.apiPath(this.urlPath() + `/duplicate/${item[this.get('idField')]}`) }

    feIndexPath = () => { return this.urlPath() }
    feNewPath = () => { return this.urlPath() + '/new' }
    feViewPath = (item) => { return this.idPath(item) }
    feEditPath = (item) => { return this.idPath(item) + '/edit' }

    beIndexCall = (loader) => { return axios.get(this.beUrlPath() + '/?' + (loader ? loader.urlParamObject.toString() : ""), headers) }
    beViewCall = (item) => { return axios.get(this.beIdPath(item), headers) }
    beDuplicateCall = (item) => { return axios.get(this.beDuplicatePath(item), headers) }
    beDeleteCall = (item) => { return axios.delete(this.beIdPath(item), headers) }
    beDeleteAllCall = () => { return axios.delete(api.apiPath(this.urlPath() + '/all', headers)) }
    beNewCall = (item) => { return axios.post(this.beUrlPath(), item, headers) }
    beEditCall = (item) => {
        //Grab the path quick first
        const path = this.beIdPath(item)
        //Map over this item to get the things we can call on the db.
        let dbItem = {}
        this.getResourceFieldValues(item).map(field => dbItem[field.resourceField.fieldName] = field.value)
        console.log(path, dbItem)
        return axios.put(path, dbItem, headers)
    }
    
    beUniqueFieldValuesCall = (field) => { return axios.get(this.beUniqueFieldValuesPath(field), headers) }

    //Calls the correct be link based on the action
    beActionCall = (action, item = null, loader) => {
        switch (action) {
            case "index":
                return this.beIndexCall(loader)
            case "view":
                return this.beViewCall(item)
            case "edit":
                return this.beViewCall(item)
            case "new":
                item = this.getDefaultItem()
                return new Promise(function(resolve, reject) {
                    resolve({data: item})
                  })
            default:
                console.log("Error in Resource.beActionCall- invalid action", action)
        }
    }

    validateInput = (item) => {
        let ret = true

        //Get the field list from the settings.
        this.fields.fieldList.map(field => {
            //Put the name in a variable and copy the value over.
            let fieldName = field.fieldName
            //If there's any validations, loop through them and handle them.
            //TODOFIX field validations
            // if (field.validations) {
            //     field.validations.map(validation => {
            //         if (validation === 'required') {
            //             ret = ret && item[fieldName] && item[fieldName] !== ''
            //         }
            //         return validation
            //     })
            // }
            return field
        })

        return ret
    }


    //This is just a shortcut getter function to get commonly used things without tons of dots in code and set "defaults"
    get = (shortcut, item = null) => {
        switch (shortcut) {
            case "urlPath":
                return this.names.urlPath
            case "friendly":
                return this.names.friendly
            case "idField":
                return this.fields.id
            case "indexTitle":
                return this.index_title
            case "indexText":
                return this.index_text
            case "viewTitle":
                return this.safeProcess(this.view_title || "", item)
            case "viewText":
                return this.safeProcess(this.view_text || "", item)
            case "editTitle":
                return this.safeProcess(this.edit_title || "", item)
            case "editText":
                return this.safeProcess(this.edit_text || "", item)
            case "newTitle":
                return this.new_title
            case "newText":
                return this.new_text
            case "newButton":
                return this.text.newButton || "Add New"
            case "newSubmit":
                return this.text.newSubmit || "Submit"
            case "editButton":
                return this.text.editButton || "Edit"
            case "editSubmit":
                return this.text.editSubmit || "Save"
            case "deleteButton":
                return this.text.deleteButton || "Delete"
            case "deleteWarning":
                return this.text.deleteWarning || "Are you sure you wish to delete?"
            case "deleteAllButton":
                return this.text.deleteAllButton || "Delete All"
            case "backToPageButton":
                return this.text.backToPageButton || "Back To Page"
            case "deleteConfirm":
                return this.options.deleteConfim === "false" ? false : true
            case "itemLink":
                return `${this.get("urlPath")}/${item[this.get('idField')]}`
            default:
                throw new Error(`Incorrect selection for 'get'- ${shortcut}`)
        }
    }




    cssId = (action, item = {}) => {
        return this.names.friendly + "-" + item[this.get('idField')] + "-" + action
    }

    processCustomCSS = (action, item = {}) => {
        let cssId = this.cssId(action, item)
        let customCSS = this[`${action}CSS`]

        resourceHelper.applyCSSToPage(cssId, customCSS)

        return cssId
    }

    sectionClass = () => {
        return this.options?.section === 'false' ? "" : 'asteroid-page-section'
    }





}





