import React from 'react'
import { AppContext } from 'contexts/appContext'
import { Link } from 'react-router-dom'
import api, {headers} from 'helpers/api'
import axios from 'axios'
import FieldDisplay from '../../fields/fieldDisplay'

import FeatureView from '../../blocks/resourceFeature'

class FeatureIndex extends React.Component {
    constructor(props, context) {
        super(props, context)

        this.asteroidSite = this.context.asteroidSite
        this.resource = this.asteroidSite.findResourceById(props.resourceFeature.connected_resource)

        this.state = {
            items: []
        }

    }

    componentDidMount = () => {
        this.loadPage()
    }

    loadPage = async () => {
        const resource = this.resource
        const permission = resource.checkPermission('index')

        //Check permissions before any call.
        if (permission) {

            //Make the api call 
            const res = await axios.get(api.apiPath('/' + resource.get("urlPath")), headers)
            let items = res.data

            this.setState({ items })
        }
    }

    
    renderFeature = (info) => {
        if (info[1].resourceFeature.checkPermission('index')) {
            return <FeatureView feature={info} {...this.props} originalResource={this.props.resource} loadPage={this.props.loadPage} action='index' />
        } else {
            return ""
        }
    }

    renderField = (info) => {
        return <FieldDisplay
            key={info.name()}
            resource={this.resource}
            action={'index'}
            resourceFieldValue={info}
            {...this.props} />
    }

    renderCategory = (info) => {
        return <div className="asteroid-page-section">
            {info.category.index_title ?
                (info.category.index_title === 'false' ?
                    ""
                    : <h2>{info.category.index_title}</h2>
                )
                : <h2>{info.category.category_name}</h2>
            }
            <div className="container">
                {info.resourceFieldValues.map(resourceFieldValue => this.renderField(resourceFieldValue))}
            </div>
        </div>
    }



    renderBlock = (resourceDisplayBlock) => {
        switch (resourceDisplayBlock.kind) {
            case "field":
                return this.renderField(resourceDisplayBlock.info)
            case "category":
                return this.renderCategory(resourceDisplayBlock.info)
            case "feature":
                return this.renderFeature(resourceDisplayBlock.info)
            default:
                console.log("Error in viewLayout - invalid block kind")
        }
    }

    renderItem = (item) => {
        let displayBlocks = this.resource.getDisplayBlocks(item)

        return displayBlocks.map((resourceDisplayBlock) => this.renderBlock(resourceDisplayBlock))
    }


    render() {
        let { resourceFeature } = this.props
        let items = this.state.items

        return <div className="asteroid-page-section">
            <h2>All Categories</h2>
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                {items.map((item) => <>

                    <Link to={`${this.resource.get("urlPath")}/${item[this.resource.get('idField')]}`} className="" style={{ display: "block" }}>
                        {this.renderItem(item)}
                    </Link>

                </>)}
            </div>
        </div>
    }


}

FeatureIndex.contextType = AppContext
export default FeatureIndex