import axios from 'axios'
import React, {useContext, useState} from 'react'
import api, { headers } from 'helpers/api'

import { AppContext } from 'contexts/appContext'
import { ResourceContext } from 'contexts/resourceContext'
import { DataConnectionContext } from 'contexts/dataConnectionContext'


const DisplayLogReverseButton = () => {
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)


    let resource = useContext(ResourceContext)
    let app = useContext(AppContext)
    let asteroidSite = app.asteroidSite
    let dataConnection = useContext(DataConnectionContext)

    let item = dataConnection.data


    const reverseChange = async (e) => {
        setLoading(true)

        let method = item.method
        let route = item.route
        let id = item.object_id
        let data = item.previous
        let urlName = route.split('/')[2]
        let objectResource = asteroidSite.findResourceByFriendlyName(urlName)

        let res;
        switch (method) {
            //GET is used for duplicating
            case "GET":
                id = objectResource.getId(item.changes)
                res = axios.delete(api.apiPath('/' + urlName + `/${id}`), headers)
                break;
            case "POST":
                id = objectResource.getId(item.changes)
                res = axios.delete(api.apiPath('/' + urlName + `/${id}`), headers)
                break;
            case "PUT":
                res = axios.put(api.apiPath('/' + urlName + `/${id}`), data, headers)
                break;
            case "DELETE":
                res = axios.post(api.apiPath('/' + urlName), data, headers)
                break;
        }

        res = await res

        if (res) {
            setLoading(false)
            setSuccess(true)
            let deleteSelf = axios.delete(api.apiPath(resource.names.urlPath + `/${item.log_id}`), headers)
            deleteSelf = await deleteSelf
            if (deleteSelf) {
                window.location.href = "/logs"
            }
        }

    }



    let permissionCheck = resource.checkPermission('edit', item)
    if (permissionCheck) {
        if (resource.base_name === 'log') {
            return <span className="small-link format-link" onClick={reverseChange}>

                {loading ? "LOADING..." : (success ? "SUCCESS" : "UNDO CHANGE")}

            </span>
        } else { return "" }
    } else { return "" }


}

export default DisplayLogReverseButton