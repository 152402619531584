import React, { useContext } from 'react'
import String from './displayFieldTypes/string'
import Email from './displayFieldTypes/email'
import Phone from './displayFieldTypes/phone'
import Boolean from './displayFieldTypes/boolean'
import Array from './displayFieldTypes/array'
import SecureRandomString from './displayFieldTypes/secureRandomString'
import Number from './displayFieldTypes/number'
import Password from './displayFieldTypes/password'
import Icon from './displayFieldTypes/icon'
import UploadImage from './displayFieldTypes/uploadImage'
import DirectImage from './displayFieldTypes/directImage'
import HTML from './displayFieldTypes/html'
import Text from './displayFieldTypes/text'
import Select from './displayFieldTypes/select'
import ObjectField from './displayFieldTypes/object'
import ExternalLink from './displayFieldTypes/externalLink'

import IdSelect from './displayFieldTypes/idSelect'
import DateField from './displayFieldTypes/date'
import MysqlDateField from './displayFieldTypes/date-mysql'


import { ResourceContext } from 'contexts/resourceContext'
import { DataConnectionContext } from 'contexts/dataConnectionContext'
import { FieldContext } from 'contexts/fieldContext'

const DefaultFieldDisplay = () => {

    let resourceFieldValue = useContext(FieldContext)
    let resource = useContext(ResourceContext)
    let dataConnection = useContext(DataConnectionContext)

    let item = dataConnection.data
    let { action, parentAction } = dataConnection

    let resourceField = resourceFieldValue.resourceField
    let displayType = resourceField.fieldType
    let value = resourceFieldValue.value

    let cssId = resourceField.processCustomCSS(parentAction, resource)

    const displayComponent = () => {
        if (value) {
            switch (displayType) {
                case 'string':
                    return <String value={value} />

                case 'email':
                    return <Email value={value} resourceField={resourceField} item={item} />

                case 'phone':
                    return <Phone value={value} resourceField={resourceField} item={item} />

                case 'resource_select':
                    return <IdSelect key={value} resourceFieldValue={resourceFieldValue} />

                case 'date':
                    return <DateField value={value} resourceField={resourceField} />

                case 'mysql-date':
                    return <MysqlDateField value={value} resourceField={resourceField} />

                case 'boolean':
                    return <Boolean value={value} />

                case "number":
                    return <Number value={value} />

                case "array":
                    return <Array value={value} />

                case "password":
                    return <Password value={value} />

                case "secureRandomString":
                    return <SecureRandomString value={value} />

                case "icon":
                    return <Icon value={value} />

                case "upload-image":
                    return <UploadImage value={value} />

                case "direct-image":
                    return <DirectImage value={value} />

                case "html":
                    return <HTML value={value} />

                case "text":
                    return <Text value={value} action={action} />

                case "select_status":
                case "select_custom":
                    return <Select value={value} resourceField={resourceField} />

                case "object":
                    return <ObjectField value={value} resourceField={resourceField} />

                case "externalLink":
                    return <ExternalLink value={value} resourceField={resourceField} item={item} />

                default:
                    console.log("ERROR! in autoField- unknown field type", displayType)
            }
        } else {
            return ""
        }

    }

    if (resourceField.options.hideIfBlank && resourceField.options.hideIfBlank !== 'false' && !value) {
        //hide the field
        return ""
    } else {
        return <div className={`${cssId} field-display-div`}>
            <div class="container">
                {resourceField.label ? <>{resourceField.printifyName(resource)}: </> : ""}
                {displayComponent()}
            </div>
        </div>
    }

}

export default DefaultFieldDisplay
