import React, { useContext } from 'react'

import { DataConnectionContext } from 'contexts/dataConnectionContext'
import { ResourceContext } from 'contexts/resourceContext'


const PageHeader = ({}) => {
    let resource = useContext(ResourceContext)
    let dataConnection = useContext(DataConnectionContext)

    let item = dataConnection.data
    let { action, parentAction } = dataConnection
    

    let title = resource.get(`${parentAction}Title`, item)

    let htmlInput = title ? title.indexOf('<') > -1 && title.indexOf('>') > -1 : false

    if (htmlInput) { title = <div dangerouslySetInnerHTML={{ __html: title }} /> }
    else { title = <h1>{title}</h1> }

    let hasHeader = parentAction === action && resource.get(`${parentAction}Title`, item) || resource.get(`${parentAction}Text`, item)

    if (hasHeader) {
        return <div className={`page-header`}>
            <div class="container">

                <p>{resource.get(`${parentAction}Text`, item)}</p>

                {title}

            </div>
        </div>
    } else {
        return ""
    }
}

export default PageHeader