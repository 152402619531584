import React from 'react'

const ExternalLink = ({ value, resourceField, item }) => {



    return resourceField.options.newTab ?

        <a className={`${resourceField.options.linkClass}`} href={resourceField.linkURL(value)} target="_blank" >
            <span class="link-reg-text">{resourceField.linkText(value, item)}</span>
            <span class="link-hover-text">{value}</span>
        </a> :

        <a className={`${resourceField.options.linkClass}`} href={resourceField.linkURL(value)}>
            <span class="link-reg-text">{resourceField.linkText(value, item)}</span>
            <span class="link-hover-text">{value}</span>
        </a>
}

export default ExternalLink