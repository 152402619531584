import React, { useContext, useState } from 'react'
import { Form } from 'react-bootstrap'
import axios from 'axios'
import api, { headers } from 'helpers/api'

import { FieldContext } from 'contexts/fieldContext'
import { DataConnectionContext } from 'contexts/dataConnectionContext'

const ImageFile = () => {

    let resourceFieldValue = useContext(FieldContext)
    let dataConnection = useContext(DataConnectionContext)

    let item = dataConnection.data
    let { setData } = dataConnection

    const [loading, setLoading] = useState(false)
    const [raw, setRaw] = useState(null)
    const [preview, setPreview] = useState(null)

    const handleChange = async (e) => {
        if (e.target.files[0]) {

            let raw = e.target.files[0];
            let previewFile = URL.createObjectURL(e.target.files[0]);
            await setLoading(true)
            await setPreview(previewFile)

            let formData = new FormData();
            formData.append("image_raw", raw);

            console.log(formData)

            let formHeaders = { 'headers': { ...headers, "Content-Type": "multipart/form-data" } }

            console.log(formHeaders)

            let link = await axios.post(api.apiPath('/image/upload'), formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            })

            setLoading(false)
            setData({
                ...item,
                [resourceFieldValue.name()]: link.data.link
            })
        }
    }

    return <div>
        <img src={preview ? preview : resourceFieldValue.value} height="50px" alt={item.image_title} /><br />
        {loading ? "LOADING..." :
            <Form.Control type="file" name={'image_raw'} onChange={handleChange} />}
    </div>
}


export default ImageFile
