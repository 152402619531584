import React from 'react'
import { curr_user, headers, apiPath } from 'helpers/api'
import { AppContext } from 'contexts/appContext'
import axios from 'axios'


//ELIFIX convert to hooks
class ProfileSelect extends React.Component {
    constructor(props, context) {
        super(props, context)
        this.asteroidSite = context.asteroidSite
    }


    setProfile = async (e) => {
        let resource_id = e.target.getAttribute('data-kind-resource-id')
        let profile_id = e.target.getAttribute('data-profile-id')

        let data = {resource_id, profile_id}
        let url = `/auth/setProfile`

        let res = await axios.put( apiPath(url), data, headers)

        this.context.login( res.data.user, res.data.token )
        
        window.location.reload()
    }

    render() {
        if (curr_user?.profiles?.length > 1) {

            return <div id="user-profile-select">{curr_user.profiles.map((profile) => {
                let userKind = this.asteroidSite.userKinds.filter((userKind) => userKind.user_kind_id === profile.user_kind_id)[0]
                let profileName = userKind.display_name

                let userKindResource = this.asteroidSite.findResourceById(userKind.kind_resource_id)

                if (profile.active_profile) {
                } else {
                    return <><span 
                        onClick={this.setProfile} 
                        data-kind-resource-id={userKind.kind_resource_id}
                        data-profile-id={userKindResource.getId(profile)} >
                        Switch to {profileName}
                    </span>&nbsp;</>
                }
            })}</div>

        } else {
            return ""
        }
    }

}

ProfileSelect.contextType = AppContext
export default ProfileSelect