import React from 'react'


import { logoURL, siteTitle, loadingGif } from 'templates/active/siteSettings'

export default function LoadingScreen(props) {
    return <div style={{ height: '100vh', width: '100vw', textAlign: 'center' }} >
	<div style={{ height: '200px', position: 'relative' }}>
		<img alt="loading gif" src={loadingGif} style={{ position: 'absolute', width: '164px', height: '164px', marginLeft: '-82px', marginTop: '32px' }} />
		<img alt="logo" src={logoURL} style={{ position: 'absolute', width: '64px', height: '64px', marginLeft: '-32px', marginTop: '82px' }} />
	</div>

	<h1>{siteTitle}</h1>
	<h2>
	<img alt="loading gif"  src={loadingGif} style={{ height: '64px' }} /> 
    Loading...
	<img alt="loading gif"  src={loadingGif} style={{ height: '64px' }} /> </h2>
</div>
}