import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import axios from 'axios'
import api from 'helpers/api'


const ForgottenPassword = () => {

    const [state, setState] = useState({
        username: '',
        sent: false
    })

    const handleChange = (e) => {
        setState({ [e.target.name]: e.target.value })
    }

    const submitForm = (e) => {
        e.preventDefault();
        axios
            .get(api.forgotPasswordPath(state.username))
            .then(res => setState({ sent: true }))
            .catch(err => console.log(err));
    }

    return <div>
        <br /><br />
        {
            this.state.sent ? "Please check your email for the link to reset your password. If you do not see it in a few minutes, check your spam folder." : <div>
                <h2>Forgot Your Password?</h2>
                <h4>Enter your email or username & we'll send you a link.</h4>

                <Form onSubmit={submitForm} style={{ width: "800px", margin: "auto" }}>
                    <Form.Group>
                        <Form.Label>Email/Username</Form.Label>
                        <Form.Control
                            onChange={handleChange} type="text"
                            name="username" placeholder="username"
                            value={state.username} />
                        <Form.Text>Please enter.</Form.Text>
                    </Form.Group>

                    <button type="submit">Submit</button>
                </Form>
            </div>

        }
    </div>
}


export default ForgottenPassword
