import React from 'react'


const ObjectField = ({value}) => {
    return (value && Object.entries(value).length > 0 ?
    <div className="asteroid-object-field">
        {Object.entries(value).map(f => <div>
            <div>{f[0]}</div><div>{JSON.stringify(f[1])}</div>
        </div>)}
    </div> : "")
}

export default ObjectField