import React, { useContext } from 'react'
import { DataConnectionContext } from 'contexts/dataConnectionContext'

import FilterField from './filterField'

import Modal from 'asteroid/components/ui/modal/modal'


const Filter = ({ }) => {

    let dataConnection = useContext(DataConnectionContext)
    let { loader, updateLoader } = dataConnection

    const handleSearch = (e) => {
        const value = e.target.value || "all"
        const field = e.target.getAttribute('data-field')

        if (value) {
            loader.handleFilterOption(field, value)
            updateLoader(loader)
        } else {
            loader.handleRemoveFilterOption(field)
            updateLoader(loader)
        }
    }

    const clearAllFilters = () => {
        loader.clearParameter('filter')
        updateLoader(loader)
    }

    const hasValue = (field, values) => {
        let ret = values.filter((v) => v && v.field === field)[0]
        return ret?.value || ""
    }

    const renderClearAllButton = (text) => {
        let changed = loader.checkChanged('filter')
        if (changed) {
            return <div onClick={clearAllFilters} class="format-link small-link">{text}</div>
        }
    }

    const renderAbsoluteClearAllButton = (text) => {
        let changed = loader.checkChanged('filter')
        if (changed) {
            return <div onClick={clearAllFilters} class="format-link small-link" style={{ position: 'absolute' }}>{text}</div>
        }
    }

    const renderFilterDropDowns = () => {
        let fields = loader.parseFieldsToArray('filterFields')
        let values = loader.parseCurrentFilterSelection()

        return fields.map((f) => <FilterField
            key={f + values.join("")}
            field={f}
            loader={loader}
            handleSearch={handleSearch} />)
    }

    const renderModalBody = () => {
        return <div>
            {renderClearAllButton('Clear All Filters')}
            {renderFilterDropDowns()}
        </div>
    }

    return <div className="asteroid-filter-box">
        <Modal button={"Filter"} title={"Filter Options"} body={renderModalBody()} />
        {renderAbsoluteClearAllButton('x')}
    </div>

}


export default Filter;
