import React, { useContext } from 'react'
import { withRouter } from 'react-router'

import { DataConnectionContext } from 'contexts/dataConnectionContext'
import { ResourceContext } from 'contexts/resourceContext'

import BackToAllButton from '../../components/buttons/backToAllButton'
import EditButton from '../../components/buttons/editButton'
import DisplayEntriesButton from '../../components/buttons/displayEntriesButton'
import DisplayLogReverseButton from '../../components/buttons/displayLogReverseButton'


const PageOptions = ({ }) => {

    let resource = useContext(ResourceContext)
    let dataConnection = useContext(DataConnectionContext)

    let { parentAction } = dataConnection
    

    if (parentAction === 'view') {
        return <div className="page-options">
            <div>
                <div className="container">
                    {resource.getOption('indexListAction') !== 'modal' ? <BackToAllButton /> : ""}
                    <DisplayEntriesButton />
                    <EditButton/>
                    <DisplayLogReverseButton />
                </div>
            </div>
        </div>
    } else return ""


}


export default withRouter(PageOptions)

