import React, {useContext, useState} from 'react'
import AutoField from './autoField'

import { ResourceContext } from 'contexts/resourceContext'
import { DataConnectionContext } from 'contexts/dataConnectionContext'
import { FieldContext } from 'contexts/fieldContext'

const InlineEditor = ({}) => {

    const [editing, setEditing] = useState(false)

    const toggle = () => {
        setEditing(!editing)
    }
    
    let resourceFieldValue = useContext(FieldContext)
    let resource = useContext(ResourceContext)
    let dataConnection = useContext(DataConnectionContext)

    let item = dataConnection.data
    let { action } = dataConnection

    let resourceField = resourceFieldValue.resourceField


    if (resourceField.checkPermission('edit', item, item[resource.special_fields.owner_id_field]) && resource.actionOptions[`${action === 'index' ? 'indexList' : action}Edit`] === 'inline') {
        const customDisplay = resourceField.customHTML('edit')
        if (customDisplay) {
            return <div dangerouslySetInnerHTML={{ __html: resourceField.generateCustomHTML('edit', item) }}></div>
        } else {
            if (editing) {
                return <div>
                    <input value={resourceFieldValue.value} /> 
                    <span className="fas fa-check" onClick={toggle}></span> 
                    <span className="fas fa-times" onClick={toggle}></span>
                </div>
            }
            return <span style={{ position: 'relative' }}>
                <AutoField />
                <span className="fas fa-pencil-alt inline-edit-button" onClick={toggle}></span>
            </span>
        }

    } else {
        return <AutoField />
    }

}


export default InlineEditor
