import React from 'react'
import { AppContext } from 'contexts/appContext'
import {Link} from 'react-router-dom'

class IdList extends React.Component {
    constructor(props, context) {
        super(props, context)

        const { resourceFieldValue } = this.props
        let resourceField = resourceFieldValue.resourceField

        let selectedId = resourceFieldValue.value

        let settings = resourceField.options

        let site = this.context.asteroidSite

        let resource = site.findResourceByFriendlyName(settings.resource)
        if (!resource) { console.log(`ASTEROID Error- resource not set correctly for ${ resourceFieldValue.name() }`) }

        let idFieldName = ""

        if (resource) {
            idFieldName = resource.fields.id
        }


        this.state = { selectedId, settings, site, resource, idFieldName, items: [], chosen: {} }

    }

    componentDidMount = async () => {
        let temp = {}
        temp[this.state.idFieldName] = this.state.selectedId
        let item = await this.state.resource?.beViewCall(temp)

        item = item ? item.data : {}

        let value = item[this.state.settings.resourceSelectField] 

        this.setState({ item, value })
    } 

    render() { 
        let temp = {}
        temp[this.state.idFieldName] = this.state.selectedId

        return <span>
            <Link className="simple-link" to={this.state.resource?.feViewPath(temp)}>{this.state.value}</Link>
        </span>
    }
}

IdList.contextType = AppContext
export default IdList