import React, {useContext} from 'react'
import { withRouter } from 'react-router'

import MenuDropdown from './menuDropdownLink'
import MenuLink from './menuLink'

import { AppContext } from 'contexts/appContext'

/*
  This file is responsible for determining & displaying the menu.
*/

const Menu = (props) => {
    const context = useContext(AppContext)
    const menuStructure = context.asteroidSite.getMenu(props.menu)
    const { openDropdown, setOpenDropdown } = props

    return <nav>
        {menuStructure.map(menuOption =>
            menuOption.isDropdown() ?
                <MenuDropdown key={menuOption.menu_option_id} menuOption={menuOption} openDropdown={openDropdown} setOpenDropdown={setOpenDropdown} />
                :
                <MenuLink key={menuOption.menu_option_id} menuOption={menuOption} />
        )}
    </nav>
}

export default withRouter(Menu);
