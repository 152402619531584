import React from 'react'
import {AppContext} from 'contexts/appContext'

class RemoteField extends React.Component {
    constructor(props, context) {
        super(props, context)

        this.asteroidSite = this.context.asteroidSite
        this.resource = this.asteroidSite.findResourceByFriendlyName( props.resource )

        this.state = {
            value: ""
        }
    }

    componentDidMount = async () => {
        let fakeItem = { [this.resource.fields.id]: this.props.id }
        let res = await this.resource.beViewCall(fakeItem)
        let value = res.data[this.props.field]
        
        this.setState({value})
    }



    render() {
        return <span>{this.state.value}</span>
    }
}

RemoteField.contextType = AppContext
export default RemoteField