

const applyCSSToPage = (cssId, customCSS) => {
    if (customCSS) {
        customCSS = customCSS.replaceAll('#cssId', '.' + cssId)
        var style = document.createElement('style');
        style.innerHTML = customCSS;
        document.head.appendChild(style);
    }
}


export default { applyCSSToPage }