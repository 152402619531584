import MenuOption from "asteroid/classes/menuOptions/menuOption"
import {curr_user} from 'helpers/api'

let adminOptions = [
    //Administrator Settings
    { menu_option_id: 1000, displayText: "Contact Forms", permission_id: 13, symbol: "comments", order: 1, link: '/contact' },
    { menu_option_id: 1001, displayText: "User Accounts", permission_id: 13, symbol: "user", order: 2, link: '/users' },
    { menu_option_id: 1002, displayText: "ToDo Boards", permission_id: 12, symbol: "check-square", order: 3, link: '/boards' },
]

let desOptions = [
    //Design Settings
    { menu_option_id: 1003, displayText: "Menu Options", permission_id: 13, symbol: "bars", order: 1, link: '/menu_options' },
    { menu_option_id: 1004, displayText: "Edit Site Pages", permission_id: 13, symbol: "sitemap", order: 2, link: '/pages' },
]

let devOptions = [
    //Dev Settings
    { menu_option_id: 1005, displayText: "Resources Panel", permission_id: 14, symbol: "cogs", order: 1, link: '/resources' },
    { menu_option_id: 1006, displayText: "Edit Permissions", permission_id: 14, symbol: "users-cog", order: 2, link: '/permissions' },
    { menu_option_id: 1007, displayText: "View Site Logs", permission_id: 14, symbol: "clipboard-list", order: 3, link: '/logs' },
]

let adminDropdownSettings = { menu_option_id: 10012, displayText: "Administrator Settings", permission_id: 12, symbol: "cogs", order: 96 }
let desDropdownSettings = { menu_option_id: 1013, displayText: "Design Settings", permission_id: 13, symbol: "palette", order: 97 }
let devDropdownSettings = { menu_option_id: 1014, displayText: "Dev Settings", permission_id: 14, symbol: "toolbox", order: 98 }

export const addDefaultLinks = (asteroidSite) => {
    let adminDropdown = new MenuOption(adminDropdownSettings, asteroidSite)
    adminOptions.map((ao) => adminDropdown.addLinkAsDropdown(ao, asteroidSite))

    let desDropdown = new MenuOption(desDropdownSettings, asteroidSite)
    desOptions.map((ao) => desDropdown.addLinkAsDropdown(ao, asteroidSite))

    let devDropdown = new MenuOption(devDropdownSettings, asteroidSite)
    devOptions.map((ao) => devDropdown.addLinkAsDropdown(ao, asteroidSite))

    asteroidSite.topMenu.push(adminDropdown)
    asteroidSite.topMenu.push(desDropdown)
    asteroidSite.topMenu.push(devDropdown)

    let menuDefaults = [
        //Default user account menu options- these are rather unavoidable.
        { menu_option_id: 1008, displayText: "Register", permission_id: 9, symbol: "plus", order: 2, link: '/auth/register' },
        { menu_option_id: 1009, displayText: "Your Profile", permission_id: 8, link: `/users/${curr_user?.user_id}`, symbol: "user", order: 98 }
    ]
    
    let topMenuDefaults = [
        { menu_option_id: 1010, displayText: "Login", permission_id: 9, symbol: "user", order: 2, link: '/auth/login' },
        { menu_option_id: 1011, displayText: "Logout", permission_id: 8, link: '/auth/logout', symbol: "sign-out-alt", order: 99 }
    ]

    menuDefaults.map(menu => asteroidSite.addMenuOption(menu))
    topMenuDefaults.map(menu => asteroidSite.topMenu.push(new MenuOption(menu, asteroidSite)))

}