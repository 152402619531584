import React from 'react'

const Email = ({ value, resourceField, item }) => {



    return  <a className={`${resourceField.options.linkClass}`} href={`mailto: ${value}`}>
            <span class="link-reg-text">{resourceField.linkText(value, item)}</span>
            <span class="link-hover-text">{value}</span>
        </a>
    
}

export default Email