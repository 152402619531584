import React from 'react';

import './paginate.scss'

class Pagination extends React.Component {
    constructor(props) {
        super(props);

        this.stateComponent = props.stateComponent
        this.loader = props.loader
        
        this.state = {}
    }

    handlePage = (e) => {
      const value =  e.target.attributes.page.value
      this.loader.handleParameter('page', value)
    }

    render() {
      const {pager} = this.loader
      
      if(!pager) { throw new Error("Trying to paginate but no pager found. Check result from backend.") }
      const {pages, currentPage, totalPages} = pager
      const callback = this.handlePage

      return <div>

            <div className="paginationLinks">
            { currentPage && currentPage > 2 ? //"2" because there's no reason to show "prev" and "first" on the second page
              <span className="format-link"><i className="fas fa-angle-double-left" onClick={callback} page={1}></i></span>
            : "" }

            { currentPage && currentPage > 1 ? //Only show if there IS a previous page
                <span className="format-link"><i className="fas fa-angle-left" onClick={callback} page={currentPage - 1} ></i></span>
              : "" }

            { pages ? pages.map(page =>
              <span className={`format-link ${currentPage===page ? 'active-page' : ''}`} onClick={callback} page={page}>
                {page}
              </span>
            ) : "" }

            { currentPage && (currentPage < totalPages) ?
              <span className="format-link"><i onClick={callback} page={currentPage + 1}  className="fas fa-angle-right"></i></span>
            : "" }

            { currentPage && (currentPage+1 < totalPages) ? //Same reason as above, no reason to show on second to last page.
              <span className="format-link"><i className="fas fa-angle-double-right" onClick={callback} page={totalPages} ></i></span>
            : "" }
            </div>

        </div>
    }
}


export default Pagination;
