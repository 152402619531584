import Resource from 'asteroid/classes/resources/resource';
import React, {useContext} from 'react';
import { Link } from 'react-router-dom'

import { DataConnectionContext } from 'contexts/dataConnectionContext'
import { ResourceContext } from 'contexts/resourceContext'

function BackToPageButton({}) {
    

    let resource = useContext(ResourceContext)
    let dataConnection = useContext(DataConnectionContext)

    let item = dataConnection.data

    let permissionCheck = resource.checkPermission('view', item)
    let link = `${resource.get('urlPath')}/${item[resource.get('idField')]}`

    let customLink = resource.actionRedirects.backToPageLink
    if(customLink) { link =  resource.safeProcess(customLink, item) }

    if (permissionCheck) {
        return <Link to={link}>{resource.get('backToPageButton')}</Link>
    }
    else { return "" }
}

export default BackToPageButton