import React, { useContext } from 'react';
import { withRouter } from 'react-router'

import Form from '../forms/form-controller'

import { ResourceContext } from 'contexts/resourceContext'

import BackToPageButton from '../../components/buttons/backToPageButton';
import PageHeader from '../../components/pageHeader'

const DefaultEditLayout = () => {
    let resource = useContext(ResourceContext)

    return <div>
        <br /><br />
        {resource.getOption('indexListEdit') !== 'modal' ? <BackToPageButton /> : ""}
        <PageHeader />
        <div className="container">
            <Form />
        </div>
    </div>
}

export default withRouter(DefaultEditLayout)
