import resourceHelper from './resource_helper'

export default class ResourceFeature {
    constructor(data, asteroidSite) {
        this.options = {}
        Object.entries(data).map(obj => this[obj[0]] = obj[1])

        this.permissions = asteroidSite.getPermissionById(data.permission_id || 1)

        this.parentResource = asteroidSite.findResourceById(data.parent_id)
        this.connectedResource = asteroidSite.findResourceById(data.connected_resource)
    }
    
    checkPermission = (view, item = {}, ownerIdValue) => {
        if (this.permissions) {
            let check = this.permissions.checkPermission(view, item, ownerIdValue)
            return check
        } else {
            return true
        }
    }



    cssId = (action) => {
        return this.connectedResource.names.friendly + "-" + this.parentResource.names.friendly + "-" + action
    }

    processCustomCSS = (action) => {
        let cssId = this.cssId(action) 
        let customCSS = this[`${action}CSS`]

        resourceHelper.applyCSSToPage(cssId, customCSS)

        return cssId
    }

    sectionClass = () => {
        return this.options?.section === 'false' ? "" : 'asteroid-page-section'
    }

    renderTitle = (action) => {
        let featureName = this.connectedResource.names.friendly
        let displayName = this[`${action}_title`]

        if (displayName) {
            if (displayName === 'false') { featureName = "" }
            else { featureName = displayName }
        }

        return featureName

    }

}