import React, {useContext} from 'react';
import {Link} from 'react-router-dom'

import { DataConnectionContext } from 'contexts/dataConnectionContext'
import { AppContext } from 'contexts/appContext'
import { ResourceContext } from 'contexts/resourceContext'


function BackToAllButton(props) {
    let resource = useContext(ResourceContext)
    let app = useContext(AppContext)
    let asteroidSite = app.asteroidSite
    let dataConnection = useContext(DataConnectionContext)

    let item = dataConnection.data

    
    const parseInsertValues = (link = "", item) => {
        link = link.split('{')

        link = link.map((section, i) => {
            if (i > 0) {
                let fillInField = section.split('}')
                section = item[fillInField[0]] + fillInField[1]
                console.log(section, fillInField)
            }
            return section
        }).join("")

        return link
    }


    let customLink = !!resource.text.backToAllLink
    let backToAllButton = resource.text.backToAllButton || "Back To All"
    let backToAllLink = parseInsertValues(resource.text.backToAllLink, props.item) || resource.get('urlPath')

    if (resource.checkPermission('index', item, asteroidSite)) {
        if (backToAllButton !== "false") {
            if (customLink) {
                return <a href={backToAllLink}>{backToAllButton}</a>
            } else {
                return <Link to={backToAllLink}>{backToAllButton}</Link>
            }
        }
    }
    return ""
}

export default BackToAllButton