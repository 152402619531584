import React, { useContext } from 'react'
// import {AppContext} from 'contexts/appContext'

import { FieldContext } from 'contexts/fieldContext'
import { DataConnectionContext } from 'contexts/dataConnectionContext'

const SelectStatusFormField = () => {

    let resourceFieldValue = useContext(FieldContext)
    let dataConnection = useContext(DataConnectionContext)

    let item = dataConnection.data
    let { setData } = dataConnection

    const handleChange = (e) => {
        setData({
            ...item,
            [e.target.name]: e.target.value
        })
    }

    const options = () => {

        let resource = this.context.asteroidSite.findResourceById(resourceFieldValue.resourceField.parent_id)

        let statusOptions = resource.special_fields.statusOptions

        let objectOptions = JSON.parse(statusOptions)

        let options = objectOptions.map((oo) => oo.name)

        return options
    }

    return <div>
        <select onChange={handleChange} name={resourceFieldValue.name()} value={resourceFieldValue.value}>
            <option value=""></option>
            {options().map(option => <option value={option}>{option}</option>)}
        </select>
    </div>

}

// didn't know about this 
// SelectStatus.contextType = AppContext
export default SelectStatusFormField