import React from 'react'
import { NavLink } from 'react-router-dom'


import { siteTitle, logoURL } from 'templates/active/siteSettings'


function Footer() {
    return <div className='footer text-center'>
        <div className="container">
            <img alt="logo" height="64px" src={logoURL} />
            <h3>{siteTitle}</h3>

            {/* 
            <NavLink to="/pages/privacy-policy">Our Privacy Policy</NavLink><br />
            <a href="https://www.google.com/policies/privacy/partners/">How Google Analytics uses data when you use our app</a><br /> */}


        </div>
    </div>
}

export default Footer;
