import React from 'react'


const Text = ({value, action}) => {
    if(action === "index") {
        return value?.substring(0, 215) + (value?.length > 215 ? "... [Click to read more]" : "")
    } else {
        return value
    }
}

export default Text