import React, { useContext } from 'react'

import FeatureIndex from '../../components/featureIndex/featureIndex'
import { ResourceContext } from 'contexts/resourceContext'

const DisplayFeatures = () => {
    let resource = useContext(ResourceContext)

    return <div>{
        resource.features
            .filter((rf) => rf.relationship_kind === 'M-M')
            .map((resourceFeature) =>
                <FeatureIndex resourceFeature={resourceFeature} />
            )
    }</div>
}


export default DisplayFeatures