import React from 'react';


class Filter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            options: []
        }
    }

    handleSearch = (e) => {
        this.props.handleSearch(e)
    }

    clearFilter = (e) => {
        this.props.handleSearch(e)
    }

    componentDidMount = async () => {
        let { field, loader } = this.props
        let resource = loader.resource

        let options = await resource.beUniqueFieldValuesCall(field)

        this.setState({options: options.data})
    }

    render() {
        let { field, loader } = this.props

        let options = this.state.options
        let value = loader.filterOptionValue(field)

        let resource = loader.resource
        let resourceField = resource.getField(field)

        let permissionCheck = resourceField.permissions.checkPermission('index', {})

        
        console.log(permissionCheck)

        if(permissionCheck) {
            return <div>
                {loader.printFieldOptionName(field)}
    
                <select onChange={this.handleSearch} value={value} data-field={field} >
                    <option value="all">All</option>
                    {options.map(o => <option value={o.value}>{o.display}</option>)}
                </select>
    
                {value && value != 'all' ? <div onClick={this.clearFilter} data-field={field} class="format-link small-link">x</div> : ""}
            </div>
        }
        else { return "" }
    }
}


export default Filter;
