import React, { useContext } from 'react'

import DataConnection from '../../controllers/dataConnection'


import { noResultsError } from 'templates/active/siteSettings'

import { ResourceContext } from 'contexts/resourceContext'
import { DataConnectionContext } from 'contexts/dataConnectionContext'


const DisplayList = () => {
    let resource = useContext(ResourceContext)
    let dataConnection = useContext(DataConnectionContext)

    let items = dataConnection.data
    let { isFeature } = dataConnection

    let customDisplay = resource.checkCustomDisplay('indexList')
    let customDisplayTemplate = (item) => { return resource.customDisplay('indexList', item) }
    let defaultDisplayTemplate = (item) => { return <DataConnection action={'view'} incomingData={item} isList /> }

    let displayTemplate = (item) => { return customDisplay ? customDisplayTemplate(item) : defaultDisplayTemplate(item) }

    const displayItems = () => {
        if (items.length === 0 && !isFeature) {
            return <div><hr /><h3>{noResultsError}</h3><hr /></div>
        } else {
            return items.map((item) => displayTemplate(item))
        }
    }

    return <main className="index-items">
        { displayItems() }
    </main>
}

export default DisplayList