
import React, { useEffect, useState, useContext } from 'react'
import { withRouter } from 'react-router'
import { useParams } from 'react-router-dom'


import { permissionError, loadingSpinner, noResultsError } from 'templates/active/siteSettings'
import Loader from 'asteroid/classes/loader/loader'

import { DataConnectionContext } from 'contexts/dataConnectionContext'
import { AppContext } from 'contexts/appContext'
import { ResourceContext } from 'contexts/resourceContext'

import DefaultIndexLayout from '../layouts/index/defaultIndexLayout'
import DefaultViewLayout from '../layouts/view/defaultViewLayout'
import DefaultNewLayout from '../layouts/new/defaultNewLayout'
import DefaultEditLayout from '../layouts/edit/defaultEditLayout'

const DataConnection = ({ action, incomingData, isList, featureInformation }) => {
    let resource = useContext(ResourceContext)
    let app = useContext(AppContext)
    let asteroidSite = app.asteroidSite

    let nLoader = Loader.fromFeature(resource, featureInformation)
    const [loader, setLoader] = useState(nLoader);

    const [loading, setLoading] = useState(incomingData ? false : true)
    const [data, setData] = useState(incomingData)
    const [update, setUpdate] = useState(true)

    const triggerUpdate = () => { setUpdate(!update) }
    const updateLoader = (loader) => {
        setLoader(loader)
        loadPage()
    }

    let paramId = useParams().id
    let id = resource.getId(incomingData) || paramId

    let itemIdObject = action === 'index' ? null : { [resource.get('idField')]: id }

    const loadPage = async () => {
        if (permission) {
            setLoading(true)

            let res = await resource.beActionCall(action, itemIdObject, loader)

            if (res) { setData(res.data) }
            setLoading(false)
        }
    }


    useEffect(() => {
        loadPage()
    }, [id, resource, action, update]);


    const permission = resource.checkPermission(action, itemIdObject, asteroidSite)
    let customDisplay = resource.checkCustomDisplay(action)

    let viewAction = isList ? 'indexList' : 'view'
    let parentAction = isList ? 'index' : action
    let isFeature = !!featureInformation

    let dataConnection = {
        action, viewAction, parentAction,
        loading, setLoading, loadPage,
        data, setData,
        loader, updateLoader,
        id, itemIdObject,
        isList, featureInformation, isFeature,
        triggerUpdate
    }

    let CallbackComponent;
    switch (action) {
        case 'index':
            CallbackComponent = DefaultIndexLayout
            break;
        case 'view':
            CallbackComponent = DefaultViewLayout
            break;
        case 'new':
            CallbackComponent = DefaultNewLayout
            break;
        case 'edit':
            CallbackComponent = DefaultEditLayout
            break;
        default:
            console.log("ERROR IN DataConnection- invalid action")

    }

    const display = () => {
        if (permission) {
            if (loading) {
                return loadingSpinner
            } else {
                if (customDisplay) {
                    return resource.customDisplay(action, data, loadPage, resource)
                } else {
                    return <CallbackComponent />
                }
            }
        } else {
            //Error display
            return permissionError
        }
    }

    return <DataConnectionContext.Provider value={dataConnection} key={window.location.pathname}>
        {display()}
    </DataConnectionContext.Provider>

}

export default withRouter(DataConnection)




