import React, { useContext } from 'react'
import { FieldContext } from 'contexts/fieldContext'


import String from './formFieldTypes/string'
import TextArea from './formFieldTypes/textArea'
import Select from './formFieldTypes/select'
import Icon from './formFieldTypes/icon'
import LocalImage from './formFieldTypes/localImage'
import ObjectField from './formFieldTypes/objectField'
import ArrayField from './formFieldTypes/array'
import NumberField from './formFieldTypes/number'
import BooleanField from './formFieldTypes/boolean'
import ImageUploadField from './formFieldTypes/imageFile'
import PasswordField from './formFieldTypes/password'
import IdSelect from './formFieldTypes/idSelect'
import SelectStatus from './formFieldTypes/select-status'
import DateField from './formFieldTypes/dateField'
import MysqlDateField from './formFieldTypes/dateField-mysql'

const FormFields = () => {

    // Dont Understand this one
    //ELIFIX combine with the other field display and move all to the same folder
    let { resourceField } = useContext(FieldContext)

    let fieldType = resourceField.fieldType

    if (Array.isArray(fieldType)) { fieldType = fieldType[0] }

    switch (fieldType) {
        case 'string':
        case 'phone':
        case 'email':
        case 'externalLink':
            return <String />
        case 'number':
            return <NumberField />
        case 'boolean':
            return <BooleanField />
        case 'text':
        case 'html':
            return <TextArea />
        case 'select_custom':
            return <Select />
        case 'select_status':
            return <SelectStatus />
        case 'icon':
            return <Icon />
        case 'direct-image':
            return <LocalImage />
        case 'upload-image':
            return <ImageUploadField />
        case 'object':
            return <ObjectField />
        case 'array':
        case "Array":
            return <ArrayField />
        case 'password':
            return <PasswordField />
        case 'resource_select':
            return <IdSelect />
        case 'date':
            return <DateField />
        case 'mysql-date':
            return <MysqlDateField />
        default:
            console.log(`ASTEROID ERROR: undefined field type- ${fieldType}`)
            return ""
    }

}

export default FormFields

















