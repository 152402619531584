import React from 'react'

import FieldBlock from './FieldBlock'
import CategoryBlock from './CategoryBlock'
import FeatureBlock from './FeatureBlock'

const ResourceBlock = ({ resourceDisplayBlock }) => {

    switch (resourceDisplayBlock.kind) {
        case "field":
            return <FieldBlock resourceDisplayBlock={resourceDisplayBlock} />
        case "category":
            return <CategoryBlock resourceDisplayBlock={resourceDisplayBlock} />
        case "feature":
            return <FeatureBlock resourceDisplayBlock={resourceDisplayBlock} />
        default:
            console.log("Error in viewLayout - invalid block kind- ", resourceDisplayBlock.kind)
    }

}

export default ResourceBlock

