import React, { useState, useContext } from 'react'
import { Form } from 'react-bootstrap'
import { withRouter } from "react-router-dom";

import DuplicateButton from '../../components/buttons/duplicateButton';

import ResourceBlock from '../../blocks/ResourceBlock'

import { ResourceContext } from 'contexts/resourceContext'
import { DataConnectionContext } from 'contexts/dataConnectionContext'




const FormBuilder = ({ submitApiCall, deleteApiCall }) => {

    let [formColor, setFormColor] = useState('transparent')
    let [error, setError] = useState(false)

    let resource = useContext(ResourceContext)
    let dataConnection = useContext(DataConnectionContext)

    let item = dataConnection.data
    let { setData, loadPage, action } = dataConnection


    const submitForm = async (e) => {
        e.preventDefault();

        if (resource.validateInput(item)) {
            setFormStatus('loading');
            let res = await submitApiCall();

            const redirectPath = resource.getRedirect(action, item)

            if (res.status === 201 || res.status === 200) {
                setFormStatus('success');
                setError("")
                if (redirectPath) {
                    //setTimeout(() => { props.history.push(redirectPath)  }, 250);
                }
            } else {
                setFormStatus('error');
                if (res.response && (res.response.status === 400)) {
                    setError(res.response.data.message)
                } else { setError("Unknown error.") }
            }
        } else {
            setFormStatus('error');
            setError("Please check the information you entered.");
        }

        //Wait a quarter second and clear the result.
        setTimeout(() => { setFormStatus('complete') }, 250);
    }


    //ELIFIX- Move to own files inside Componenets/Buttons
    const submitButton = () => {
        let text = ''
        if (action === 'edit') {
            text = resource.get("editSubmit")
        } else {
            text = resource.get("newSubmit")
        }
        return <button type='submit'>{text}</button>
    }


    //make sure to bring in action and resource correctly
    const deleteButton = () => {
        if (action === 'edit' && resource.checkPermission('delete', item)) {
            return <button onClick={deleteApiCall}>{resource.get("deleteButton")}</button>
        }
    }

    //PRIVATE HELPER FUNCTIONS
    //Used to set the status of the form- display confirmations & errors
    //Takes in 'loading', 'success', 'error', or 'complete'
    const setFormStatus = (status) => {
        setFormColor(({ loading: 'var(--loading-color)', success: 'var(--success-color)', error: 'var(--error-color)', complete: 'transparent' })[status])
    }

    const renderDisplayBlocks = () => { return resource.getDisplayBlocks(item).map((db) => <ResourceBlock resourceDisplayBlock={db} />) }
    const renderError = () => { return error ? <div style={{ backgroundColor: 'var(--error-color)', padding: '10px' }}>{error}</div> : "" }

    return <Form style={{ backgroundColor: formColor }} onSubmit={submitForm} id={`${resource.names.us}-${resource.getId(item)}`} >
        {renderError()}
        {renderDisplayBlocks()}

        {/*//ELIFIX- Move to new button componenets here*/}
        <div className="page-options">
            <div>
                <div className="container">
                    
                    {submitButton()}
                    {action === 'edit' ? <DuplicateButton /> : "" }
                    {action === 'edit' ? deleteButton() : "" }
                </div>
            </div>
        </div>

    </Form >


}

export default withRouter(FormBuilder)

















