export default class ResourceFieldValue {
    constructor(resourceField, value = resourceField.options.defaultValue ) {
        this.resourceField = resourceField
        this.value = value
    }

    name = () => {
        return this.resourceField.fieldName
    }

    setValue = (value) => {
        this.value = value
    }

    
}