import React, { useContext, useState } from 'react'
import { Form } from 'react-bootstrap'

import { FieldContext } from 'contexts/fieldContext'
import { DataConnectionContext } from 'contexts/dataConnectionContext'

const PasswordFormField = () => {

    let resourceFieldValue = useContext(FieldContext)
    let dataConnection = useContext(DataConnectionContext)

    let item = dataConnection.data
    let { setData } = dataConnection

    const [change, setChange] = useState('')
    const [confirm, setConfirm] = useState('')
    const [error, setError] = useState('')
    const [stateObject, setStateObject] = useState({
        change: change,
        confirm: confirm,
        error: error
    })


    const handleChange = async (e) => {
        await setStateObject({
            ...stateObject,
            [e.target.name]: e.target.value
        })

        if (change === confirm) {
            setError('')

            setData({
                ...item,
                [e.target.name]: e.target.value
            })
        }
        else {
            setError("Please make sure your passwords match.")
        }

    }

    return <>

        <Form.Control
            type='password'
            onChange={handleChange}
            name={'change'}
            placeholder={"Change Password"}
            value={change}
        />
        <Form.Control
            type='password'
            onChange={handleChange}
            name={'confirm'}
            placeholder={"Confirm Password"}
            value={confirm}
        />
        {error}

    </>

}

export default PasswordFormField