import React, { useContext } from 'react'
import { DataConnectionContext } from 'contexts/dataConnectionContext'

const Toolbar = ({ }) => {

    let dataConnection = useContext(DataConnectionContext)
    let { loader } = dataConnection

    if (loader) {
        return <>
            {
                loader.checkSortSearchFilterOptions() ?
                    <div className="asteroid-toolbar-options">

                        {loader.displaySearch()}
                        {loader.displayFilter()}
                        {loader.displaySort()}
                        
                    </div>
                    : ""}

            <div>
                {loader.printSummary()}
            </div>
        </>

    } else return ""

}

export default Toolbar