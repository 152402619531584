import React from 'react'

export const FieldContext = React.createContext({})


// let dataConnection = {
//     action,
//     loading, setLoading,
//     data, setData,
//     loader, setLoader,
//     id
// }