import { curr_user } from 'helpers/api'

class PermissionSetting {
    constructor(data) {
        this.permissionObject = {}
        this.permission_id = data.permission_setting_id

        this.permission_rules = data.permission_rules.map((pr) => new PermissionRule(pr))
    }

    findRulesByAction(action) {
        let rules = this.permission_rules.filter((pr) => pr.action === action)
        if (rules.length > 0) {
            return rules
        } else {
            return false
        }
    }

    checkPermission = (action, item = {}, ownerIdValue) => {
        let rules = []

        switch (action) {
            case "index":
                rules = this.findRulesByAction('index') || this.findRulesByAction('display') || this.findRulesByAction('all')
                break;
            case "view":
                rules = this.findRulesByAction('view') || this.findRulesByAction('display') || this.findRulesByAction('all')
                break;
            case "edit":
                rules = this.findRulesByAction('edit') || this.findRulesByAction('form') || this.findRulesByAction('all')
                break;
            case "new":
                rules = this.findRulesByAction('new') || this.findRulesByAction('form') || this.findRulesByAction('all')
                break;
            case "delete":
                rules = this.findRulesByAction('delete') || this.findRulesByAction('all')
                break;
        }

        let check = false
        rules.map((r) => check = check || r.check(item, ownerIdValue, action))

        return check
    }
}


class PermissionRule {
    constructor(data) {
        Object.entries(data).map((arr) => this[arr[0]] = arr[1])
    }

    check = (item = {}, ownerIdValue, action) => {

        let passes = true

        if (this.user_kind_override) {
            switch (this.user_kind_override) {
                case "any":
                    passes = !!curr_user
                    break;
                case "all":
                    passes = true
                    break;
                case "none":
                    passes = !curr_user
                    break;
                case "self":
                    if(action === 'edit' || action === 'view' || action === 'delete'){ passes = ownerIdValue === curr_user?.user_id }
                    if(action === 'index' || action === 'new'){ passes = true }
                    break;
            }
        } else {
            if (this.user_kind_id) {
                if (!curr_user) {
                    passes = false
                }
                else {
                    passes = curr_user.get('user_kind_id') === this.user_kind_id

                    if (passes && this.user_kind_role_id) {
                        passes = curr_user.get('user_kind_role_id') === this.user_kind_role_id
                    }
                }
            }
        }

        let ret = null
        if (this.access_allowed) { ret = passes }
        else { ret = !passes }
        return ret
    }
}


export { PermissionSetting }
