import React, {useContext} from 'react';
import { Link } from 'react-router-dom'

import { DataConnectionContext } from 'contexts/dataConnectionContext'
import { ResourceContext } from 'contexts/resourceContext'


function DisplayEntriesButton(props) {
    let resource = useContext(ResourceContext)
    let dataConnection = useContext(DataConnectionContext)

    let item = dataConnection.data
    
    if (resource.base_name === 'resource' && item.base_name !== 'resource') {
        return <Link to={item.names.urlPath}>See Entries</Link>
    }
    else { return "" }
}

export default DisplayEntriesButton