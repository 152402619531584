import React from 'react';
import Helmet from 'react-helmet'
import { withRouter } from 'react-router';

import Header from './header';
import Footer from './footer';
import Body from './body';


import { siteTitle, siteTagline, themeOptions }  from 'templates/active/siteSettings'

const Layout = () => {

    return <>
        <Header />
        <Helmet><title>{`${siteTitle}- ${siteTagline}`}</title></Helmet>
        <Body />
        {themeOptions.displayFooter ? <Footer /> : ""}
    </>

}

export default withRouter(Layout);
