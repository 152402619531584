class User {
    constructor(data) {
        console.log(data)
        Object.entries(data).map(obj => this[obj[0]] = obj[1])



    }


    profile = () => {
        return this.profiles ? this.profiles[0] : false
    }

    get = (choice) => {
        switch (choice) {
            case "kind_id":
            case "user_kind_id":
                return this.profile()?.user_kind_id

            case "user_kind_role_id":
            case "user_role_id":
            case "role_id":
                return this.profile()?.user_kind_role_id

            
        }
    }

}

export default User
