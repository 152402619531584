import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';

import Layout from './site/structure/Layout';
import LoadingScreen from './site/structure/loadingScreen';

import { AppContext, appDefaults } from 'contexts/appContext'
import { curr_user, expireTokenCheck, apiPath, headers, loginCookies, logoutCookies } from 'helpers/api'

import AsteroidSite from './asteroid/classes/asteroidSite/asteroidSite'
import axios from 'axios';



const App = () => {
    const [asteroidSite, setAsteroidSite] = useState(new AsteroidSite())
    const [loading, setLoading] = useState(true)

    //Store user & token in app state so that the app rerenders on login/logout
    const [user, setUser] = useState(appDefaults.user)
    const [token, setToken] = useState(appDefaults.token)


    useEffect( () => {
        //This checks to see if a user's login is expired, logs them out, or just goes on. 
        if (curr_user) { if (expireTokenCheck()) { logout() } }

        axios.get(apiPath('/site'), headers).then(res => {
            let incomingAsteroidSite = asteroidSite
            incomingAsteroidSite.initializeSite(res)

            setAsteroidSite(incomingAsteroidSite)
            setLoading(false)
        })
    }, [])

    const logout = () => {
        logoutCookies()
        setUser({})
        setToken(null)
        window.location.replace('/?loggedIn=false');
    };

    const login = (incomingUser, incomingToken) => {
        loginCookies(incomingUser, incomingToken)
        setUser(incomingUser)
        setToken(incomingToken)
        window.location.replace('/?loggedIn=true');
    };

    let contextValue = {
        asteroidSite: asteroidSite,
        user: user,
        token: token,
        logout: logout,
        login: login
    }

    if (loading) {
        return <LoadingScreen />
    } else {
        return <AppContext.Provider value={contextValue}>
            <Layout />
        </AppContext.Provider>
    }



}

export default withRouter(App);
