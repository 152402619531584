import React from 'react'
import resourceHelper from './resource_helper'

export default class ResourceCategory {
    constructor(data, asteroidSite) {
        Object.entries(data).map(obj => this[obj[0]] = obj[1])

        this.permissions = asteroidSite.getPermissionById(data.permission_id || 1)
    }

    checkPermission = (view, item = {}, ownerIdValue) => {
        if (this.permissions) {
            let check = this.permissions.checkPermission(view, item, ownerIdValue)
            return check
        } else {
            return true
        }
    }

    checkCustomDisplay = (action) => {
        return this[`${action}HTML`]
    }

    customDisplay = (action, data) => {
        let category = data.category
        let resourceFieldValues = data.resourceFieldValues

        let item;

        let rawInfo = this[`${action}HTML`]

        if (rawInfo.indexOf('{items}') >= 0 && rawInfo.indexOf("###") >= 0) {
            item = this.replaceTemplateWithValues(resourceFieldValues, rawInfo)
            return <div dangerouslySetInnerHTML={{ __html: item }} />
        } else {
            item = this.replaceFieldsWithValues(resourceFieldValues, rawInfo)
            return <div dangerouslySetInnerHTML={{ __html: item }} />
        }
    }

    replaceFieldsWithValues = (resourceFieldValues, rawInfo) => {
        rawInfo = rawInfo.split('{')
        if (rawInfo.length > 1) {
            for (var i = 1; i < rawInfo.length; i++) {
                let pair = rawInfo[i].split('}')
                let varName = pair[0]
                let value = resourceFieldValues.filter((rfv) => rfv.name() === varName)[0].value || ""
                rawInfo[i] = value + pair[1]
            }
        }
        rawInfo = rawInfo.join("")
        return rawInfo
    }

    replaceTemplateWithValues = (resourceFieldValues, rawInfo) => {
        let pair = rawInfo.split('###')
        let main = pair[0]
        let template = pair[1]

        let items = []

        resourceFieldValues.map((rfv) => items.push(template.replaceAll(`{value}`, rfv.value).replaceAll('{label}', rfv.resourceField.displayName)))

        main = main.replace('{items}', items.join(""))

        return main
    }


    cssId = (action, resource) => {
        return  action + resource.names.friendly + "-" + this.category_name + "-category-fields"
    }

    processCustomCSS = (action, resource) => {
        let cssId = this.cssId(action, resource)
        let customCSS = this[`${action}CSS`]

        resourceHelper.applyCSSToPage(cssId, customCSS)

        return cssId
    }

    renderTitle = (action) => {
        if (this[`${action}_title`]) {
            if (this[`${action}_title`] === 'false') {
                return ""
            } else {
                return <h2>{this.category[`${action}_title`]}</h2>
            }
        } else {
            return <h2>{this.category_name}</h2>
        }
    }
}

    
