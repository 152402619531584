import React, { useContext } from 'react'

import { FieldContext } from 'contexts/fieldContext'
import { DataConnectionContext } from 'contexts/dataConnectionContext'

const Field = () => {

    let resourceFieldValue = useContext(FieldContext)
    let dataConnection = useContext(DataConnectionContext)

    let item = dataConnection.data
    let { setData } = dataConnection

    const handleChange = (e) => {
        setData({
            ...item,
            [e.target.name]: e.target.value
        })
    }

    let options = () => {

        let o = resourceFieldValue.resourceField.options.selectOptions.substring(1)
        o = o.substr(0, o.length - 1)
        let options = o.split('","')

        return options
    }


    return <div>
        <select onChange={handleChange} name={resourceFieldValue.name()} value={resourceFieldValue.value}>
            <option value=""></option>
            {options().map(option => <option value={option}>{option}</option>)}
        </select>
    </div>

}


export default Field;