import React, { useEffect, useState, useContext } from 'react'
import { AppContext } from 'contexts/appContext'
import { DataConnectionContext } from 'contexts/dataConnectionContext'
import { FieldContext } from 'contexts/fieldContext'


const IdList = () => {

    let resourceFieldValue = useContext(FieldContext)
    let dataConnection = useContext(DataConnectionContext)
    let asteroidSite = useContext(AppContext).asteroidSite

    let item = dataConnection.data
    let { setData } = dataConnection

    let resourceField = resourceFieldValue.resourceField
    let settings = resourceField.options


    let resource = asteroidSite.findResourceByFriendlyName(settings.resource)
    if (!resource) { console.log(`ASTEROID Error- resource not set correctly for ${resourceFieldValue.name()}`) }

    let idFieldName = ""

    if (resource) {
        idFieldName = resource.fields.id
    }

    const [selectedId, setselectedId] = useState(resourceFieldValue.value);
    const [items, setItems] = useState([]);
    const [chosen, setChosen] = useState({})


    useEffect(() => {
        async function fetchData() {

            let items = await resource?.beIndexCall()
            items = items ? items.data : []

            if (settings.resourceSelectFilter) {
                let fields = settings.resourceSelectFilter.split('=')
                let parent_field = fields[0]
                let connect_field = fields[1]

                items = items.filter((possible) => possible[connect_field] === item[parent_field])
            }


            let chosen = items?.filter((possible) => possible[idFieldName] === selectedId)[0]

            setChosen(chosen)
            setItems(items)
        }
        fetchData();
    }, [])



    const handleChange = (e) => {
        setData({
            ...item,
            [e.target.name]: e.target.value
        })
    }



    return <div>
        <select onChange={handleChange} name={resourceFieldValue.resourceField.fieldName} value={`${resourceFieldValue.value}`}>
            <option value="0"></option>
            {items.map(option => <option value={`${option[idFieldName]}`}>

                {option[settings.resourceSelectField]}

            </option>)}
        </select>
    </div>
}



export default IdList;