import React from 'react'

export const siteOptions = {
    backendLive: 'auto',
}

export const localBeLink = `http://localhost:4001/api`
export const liveBeLink = `https://map-dev-p.herokuapp.com/api`

//export const liveGoogleId = 'G-FZWZVMCGYJ'

export const apiPathLink = siteOptions.backendLive ? ( siteOptions.backendLive === 'auto' ? ( window.location.hostname === 'localhost' ? localBeLink : liveBeLink ) : liveBeLink ) : localBeLink

export const siteTitle = "Proximitii"
export const logoURL = "https://www.areavibes.com/images/header-assets.svg#av-logo"
export const loadingGif = "/images/loading/balls.png"
export const siteTagline = "Admin Panel"


export const loadingSpinner = <div style={{textAlign:'center'}}><img alt="loading gif"  src={loadingGif} style={{ height: '64px', margin: 'auto', display: 'block' }} /> </div>
export const permissionError = <div>Sorry, you do not have permissions to view this page.</div>
export const noResultsError = <div>Sorry, there was no results returned for that search.</div>
export const missingError = <div>Sorry, that page seems to be missing.</div>

export const googleFonts = ['Lato', 'Open Sans', 'Poppins']



export const themeOptions = {
    displayFooter: true
}

/* ----------------------------
MENU OPTIONS 
----------------------------- */
export const menuOptions = {
    /* Whether or not the menu stays open or you can close it */
    menuPersist: false,
    /* Whether or not to show the menu on the homepage */
    menuOnHome: true,

}