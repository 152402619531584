import React, { useContext, useState } from 'react'
import { Form } from 'react-bootstrap'

import { FieldContext } from 'contexts/fieldContext'
import { DataConnectionContext } from 'contexts/dataConnectionContext'


const ObjectFieldFormField = () => {

    let resourceFieldValue = useContext(FieldContext)
    let dataConnection = useContext(DataConnectionContext)

    let item = dataConnection.data
    let { setData } = dataConnection

    let obj = resourceFieldValue.value || {}

    //Represent the object as an array of objects with the key, value, and order.
    const [entries, setEntries] = useState( Object.entries(obj).map((pair, i) => ({ key: pair[0], value: pair[1], order: i }) ))

    const handleValue = () => {
        let retObj = {}


        console.log(entries)

        entries.map((e) => retObj[e.key] = e.value)


        console.log(retObj)


        setData({
            ...item,
            [resourceFieldValue.resourceField.fieldName]: retObj
        })

    }

    const handleKeyChange = (e) => {
        let newKeyName = e.target.value
        let order = Number.parseInt(e.target.getAttribute('data-order'))

        let changed = entries.filter((e) => e.order === order)[0]

        changed.key = newKeyName

        setEntries(entries)
        handleValue()
    }

    const handleValueChange = (e) => {
        let newValue = e.target.value
        let order = Number.parseInt(e.target.getAttribute('data-order'))

        let changed = entries.filter((e) => e.order === order)[0]
        changed.value = newValue

        setEntries(entries)
        handleValue()
    }

    const addField = (e) => {
        let name = e.target.getAttribute('data-name')

        let newEntry = { key: name, value: "", order: highestOrder() + 1 }
        entries.push(newEntry)

        setEntries( entries )
        handleValue()
    }

    const deleteField = async (e) => {
        let order = Number.parseInt(e.target.getAttribute('data-order'))

        await setEntries( entries.filter((e) => e.order !== order) )

        handleValue()
    }

    const highestOrder = () => {
        return entries.sort((a, b) => b.order - a.order)[0]?.order || 0
    }



    let objArr = Object.entries(obj)

    let optionIdeas = resourceFieldValue.resourceField.options.objectOptions || ""
    optionIdeas = optionIdeas.substring(1, optionIdeas.length - 1).split('","')


    optionIdeas = optionIdeas.filter((optionIdea) => {
        let match = objArr.filter((existingPair) => existingPair[0] === optionIdea)[0]
        return !match
    })

    return <>

        {entries.sort((a, b) => a.order - b.order).map((entry) => {
            return <div>

                <div style={{ width: '150px', display: 'inline-block' }}>
                    <Form.Control type='text'
                        onChange={handleKeyChange}
                        name={`${entry.key}`}
                        id={`${entry.key}-key`}
                        data-order={entry.order}
                        value={entry.key}
                        key={entry.order}
                    />
                </div>

                <div style={{ width: 'calc(100% - 200px)', display: 'inline-block' }}>
                    <Form.Control type='text'
                        onChange={handleValueChange}
                        name={`${entry.key}`}
                        id={`${entry.key}-value`}
                        data-order={entry.order}
                        value={entry.value}
                        key={entry.order}
                    />
                </div>

                <div style={{ width: '50px', display: 'inline-block' }}>
                    <span class="format-link small-link" onClick={deleteField}
                        data-order={entry.order} data-name={entry.name}>x</span>
                </div>

            </div>
        })}

        <span className="format-link small-link" data-name={"newfield"} style={{ cursor: 'pointer' }} onClick={addField}>Add New Field (+)</span>

        {optionIdeas.map((o) => <span data-name={o} className="format-link small-link" style={{ cursor: 'pointer' }} onClick={addField}>{o}</span>)}
    </>

}

export default ObjectFieldFormField