import React from 'react';
import { logoURL, siteTitle, siteTagline } from './siteSettings'


import { Link } from 'react-router-dom'

function Home(props) {


    return <div id="page">
        <div className="fluid-container" id="hero">
            <div className="container">
                <div style={{ width: "100%" }}>
                    <h1>{siteTitle}</h1>
                    <h3>{siteTagline}</h3>
                    <h4></h4>
                    <br />
                    <Link to="/auth/login">Login</Link> &nbsp;
                    <Link to="/auth/register">Register</Link>
                </div>
            </div>
        </div>
    </div>
}

export default Home;
