import React, { useContext } from 'react'
import { DataConnectionContext } from 'contexts/dataConnectionContext'

const Search = ({ }) => {


    let dataConnection = useContext(DataConnectionContext)
    let { loader, updateLoader } = dataConnection


    const handleSearch = (e) => {
        const value = e.target.value
        loader.handleParameter('search', value)
        updateLoader(loader)
    }

    const clearSearch = (e) => {
        loader.clearParameter('search')
        updateLoader(loader)
    }

    let value = loader.getParam('search')

    return <span className="asteroid-search-bar">
        <input
            type="text"
            onChange={handleSearch}
            placeholder={`Search...`}
            value={value}
        />


        {value ? <span class="format-link small-link" style={{ position: 'absolute' }} onClick={clearSearch}>x</span> : ""}

    </span>

}


export default Search;
