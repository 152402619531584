import React, { useEffect, useContext } from 'react';
import { withRouter } from 'react-router'

import Form from '../forms/form-controller'

import { DataConnectionContext } from 'contexts/dataConnectionContext'
import { ResourceContext } from 'contexts/resourceContext'
import PageHeader from '../../components/pageHeader'

const DefaultNewLayout = () => {
    return <div>

        <PageHeader />

        <div className="container">
            <Form />
        </div>
    </div>


}

export default withRouter(DefaultNewLayout)

