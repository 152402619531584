import React, {useContext} from 'react';
import { Link } from 'react-router-dom'

import Modal from 'asteroid/components/ui/modal/modal'

import View from '../../layouts/view/defaultViewLayout'

import { DataConnectionContext } from 'contexts/dataConnectionContext'
import { AppContext } from 'contexts/appContext'
import { ResourceContext } from 'contexts/resourceContext'

function ViewButton({}) {
    let resource = useContext(ResourceContext)
    let app = useContext(AppContext)
    let asteroidSite = app.asteroidSite
    let dataConnection = useContext(DataConnectionContext)

    let item = dataConnection.data
    let { update, loadPage } = dataConnection
    

    if (resource.checkPermission('edit', item, asteroidSite)) {
        let itemLink = `${resource.get("urlPath")}/${item[resource.get('idField')]}`

        if (!resource.getOption('indexListAction') || resource.getOption('indexListAction') === 'page') {
            return <Link to={itemLink} className="format-link small-link">
                <span className="fas fa-eye"></span>
            </Link>
        } else {
            return <Modal button={<span className="fas fa-eye"></span>}
                title={""}
                body={<View 
                    asteroidSite={asteroidSite}
                    update={update}
                    action="view"
                    item={item} 
                    loadPage={loadPage} />}
                smallLink={true} />
        }

    }
    return ""
}

export default ViewButton
