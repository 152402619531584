
export default class ResourceDisplayBlock {

    constructor(data) {
       this.order = data.order
       this.kind = data.kind
       this.info = data.info
    }




}