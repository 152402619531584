import React from 'react'

class DateField extends React.Component {
    constructor(props) {
        super(props)
    }

    printDate() {
        let {resourceField, value} = this.props

        value=new Date(value)

        switch (resourceField.text.dateDisplay) {
            case "date-string":
                return `${value.toDateString()}` 
            case "datetime":
                let hours = value.getHours() > 12 ? value.getHours() - 12 : value.getHours()
                let ampm = value.getHours() > 11 ? "pm" : "am"

                if(value.getHours() === 0) { hours = 12; ampm = 'am'}

                return `${value.getMonth()+1}/${value.getDate()}/${value.getFullYear()} ${hours}:${value.getMinutes()}${ampm}` 
            case "timeago":
                //TODOFIX add timeago functionality
                return value.toString()
            case "date":
                return `${value.getMonth()+1}/${value.getDate()}/${value.getFullYear()}` 
            default:
                return value.toString()

        }
    }

    render() {
        return <span>
            {this.printDate()}
        </span>
    }
}

export default DateField