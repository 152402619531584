
import React, { useContext } from 'react'
import { FieldContext } from 'contexts/fieldContext'
import { DataConnectionContext } from 'contexts/dataConnectionContext'
import { ResourceContext } from 'contexts/resourceContext'

import DefaultDisplayField from '../fields/fieldDisplay'
import DefaultFormField from '../fields/formField'


const FieldBlock = ({ resourceDisplayBlock }) => {
    let info = resourceDisplayBlock.info
    let resourceField = info.resourceField

    let resource = useContext(ResourceContext)
    let dataConnection = useContext(DataConnectionContext)

    let item = dataConnection.data
    let { action, parentAction } = dataConnection

    let FieldComponent = (action === 'view' || action === 'index') ? DefaultDisplayField : DefaultFormField

    const displayField = () => {
        if (resourceField.checkPermission(parentAction, item, item[resource.special_fields.owner_id_field])) {
            const customDisplay = resourceField.customHTML(parentAction)
            if (customDisplay) {
                return <div dangerouslySetInnerHTML={{ __html: resourceField.generateCustomHTML(parentAction, item) }}></div>
            } else {
                return <FieldComponent key={info.name()} />
            }
        } else {
            return null
        }
    }


    return <FieldContext.Provider value={info} key={info.name()}>
        {displayField()}
    </FieldContext.Provider>

}

export default FieldBlock
