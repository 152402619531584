import React from 'react'
import RemoteField from 'asteroid/components/asteroidResource/fields/remoteField'
import resourceHelper from './resource_helper'

/*
        ==Field Types==
        "string"- a string, gives a box
        "text"- a long string, text box
        "boolean"- a boolean, does a checkbox
        "number" 
        "html"
        "object"  
        "text-array"
        "reference" 
        "local-image" 
        "hidden"
        "icon"
        ["select-open"] 
        ["select-draft"] 
        ["select-custom", [[x, X], [y, Y]]
*/

export default class ResourceField {
    constructor(data, asteroidSite) {
        this.parent_id = data.parent_id
        this.fieldName = data.name
        this.default = data.default === null ? "" : data.default
        this.fieldType = data.displayType || "string"
        this.permissions = asteroidSite.getPermissionById(data.permission_id || 1)
        this.validations = data.validations || []
        
        Object.entries(data).map(obj => this[obj[0]] = obj[1])

        this.label = data.label
        this.order = data.order
        this.titleField = data.titleField

        this.resource_category_id = data.resource_category_id

        this.displayName = data.displayName || ""

        this.text = data.text || {}
        this.options = data.options || {}

    }

    checkPermission = (view, item = {}, ownerId = null) => {
        if (this.permissions) {
            return this.permissions.checkPermission(view, item, ownerId)
        } else {
            return true
        }
    }

    customHTML = (view) => {
        let cHTML = this[`${view}HTML`] ? this[`${view}HTML`] : false

        return cHTML
    }

    generateCustomHTML = (view, item) => {
        let cHTML = this[`${view}HTML`] ? this[`${view}HTML`] : false
        let value = item[this.fieldName]
        cHTML = cHTML.replace('{value}', value).replace('{label}', this.displayName)
        return cHTML
    }

    printifyName = (resource) => {
        return this.displayName || this.fieldName
            //replaces the "example_" part of "example_name" and "example_case_2"
            .replace(`${resource.names.ls}_`, '')
            //replaces all other underscores with spaces
            .replace(/_/g, ' ')
            //and capitalizes each letter
            .replace(/(?: |\b)(\w)/g, function (key) { return key.toUpperCase() })
    }

    getValue = () => {

    }

    getResourceSelectInfo = (value) => {
        if (this.fieldType === 'resource_select') {
            return <RemoteField resource={this.options.resource} field={this.options.resourceSelectField} id={value} />
        }
    }


    linkURL = (value) => {
        return value.indexOf('https://') === 0 ? value : "https://" + value
    }
    linkText = (value, item) => {
        let linkTitle = this.options.linkTitle
        if(linkTitle) {
            if(linkTitle[0] === '{') {
                linkTitle = linkTitle.substring(1, linkTitle.length - 1)
                return item[linkTitle]
            } else {
                return linkTitle
            }
        } else { 
            return value
        }
    }







    cssId = (action, resource) => {
        return resource.names.friendly + "-" + this.fieldName + "-" + action
    }

    processCustomCSS = (action, resource) => {
        let cssId = this.cssId(action, resource) 
        let customCSS = this[`${action}CSS`]

        resourceHelper.applyCSSToPage(cssId, customCSS)

        return cssId
    }

    
}