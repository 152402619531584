import React from 'react'
import { withRouter } from 'react-router'
import MenuLink from './menuLink'

//Controls state for a dropdown menu

const DropdownOption = ({ menuOption, setOpenDropdown, openDropdown }) => {

    let menuId = menuOption.menu_option_id
    let showDropdown = menuId === openDropdown
    const optionDisplay = { display: (showDropdown ? 'block' : 'none') }

    const toggleMenu = (e) => {
        if (showDropdown) {
            setOpenDropdown(0)
        } else {
            setOpenDropdown(menuId)
        }
    }

    const displayTitle = () => {
        return <div className={"hmenu-dropdown-title"} onClick={toggleMenu} style={{ width: '100%' }}>
            <span className={`fas fa-${menuOption.symbol}`}></span>
            <span>
                {menuOption.name}
                <span className={`fas fa-caret-${showDropdown ? 'down' : 'up'} `}></span>
            </span>
        </div>
    }

    if (menuOption.permissions?.checkPermission('view')) {
        return <span className="hmenu-dropdown hmenu-item">
            {displayTitle()}
            <div className="hmenu-dropdown-options" style={optionDisplay}>
                {menuOption.getLinks().map(link => <MenuLink menuOption={link} parentName={menuOption.name} />)}
            </div>
        </span>
    } else {
        return ""
    }
}


export default withRouter(DropdownOption);
