import React, {useContext, useState} from 'react';
import { Link } from 'react-router-dom'

import Modal from 'asteroid/components/ui/modal/modal'

import DataConnection from '../../controllers/dataConnection';


import { DataConnectionContext } from 'contexts/dataConnectionContext'
import { AppContext } from 'contexts/appContext'
import { ResourceContext } from 'contexts/resourceContext'


const EditButton = () => {
    const [closed, setClosed] = useState(false)

    let resource = useContext(ResourceContext)
    let app = useContext(AppContext)
    let asteroidSite = app.asteroidSite
    let dataConnection = useContext(DataConnectionContext)

    let item = dataConnection.data
    let { action, update, loadPage } = dataConnection

    // const loadPage = (update) => {
    //     if (update) { setClosed(true) }
    //     //this.props.loadPage(update)
    // }


    if (resource.checkPermission('edit', item, asteroidSite)) {
        let path = resource.get('urlPath')
        let id = resource.getId(item)
        let link = `${path}/${id}/edit`

        if (!resource.getOption('indexListEdit') || resource.getOption('indexListEdit') === 'page') {
            return <Link to={link} className="format-link">
                Edit <span className="fas fa-pencil-alt"></span>
            </Link>
        } else if (resource.getOption('indexListEdit') === 'modal') {
            return <Modal button={"Edit"}
                title={"Edit"}
                closeTrigger={closed}
                body={<DataConnection incomingData={item} action={'edit'} />}
            />
        } else {
            return ""
        }


    }
    return ""

}

export default EditButton
