import React, { useContext } from 'react'
import { Switch, Route, withRouter } from 'react-router'

/* 

This is the controller for all components that are using the default options. 

This file has a few major responsibilities:
1) Pull the component settings and put it into a context
2) Link to the default index, view, edit, new funtionality; which ends up linking to the BE


*/

import FourOhFour from 'site/structure/404Component'

import { AppContext } from 'contexts/appContext'
import { ResourceContext } from 'contexts/resourceContext'


import DataConnection from './dataConnection'


const DefaultController = (props) => {
    let context = useContext(AppContext)

    //Get the url and the settings based off of that. These go into a context to be used.
    const url = props.match.params.urlPath
    const resource = context.asteroidSite.findResourceByFriendlyName(url)

    //Double check to make sure we have the settings. If not, most likely a typo on the url, so display a 404.
    if (resource) {
        return <ResourceContext.Provider value={resource} key={url}>
            <div className="page-container main-page-container" id={`${url}`} >
                <Switch>
                    <Route path="/:urlPath" exact>
                        <DataConnection key={window.location.pathname} action={'index'} />
                    </Route>

                    <Route path="/:urlPath/new" exact>
                        <DataConnection key={window.location.pathname} action={'new'} />
                    </Route>

                    <Route path="/:urlPath/:id" exact>
                        <DataConnection key={window.location.pathname} action={'view'} />
                    </Route>

                    <Route path="/:urlPath/:id/edit" exact>
                        <DataConnection key={window.location.pathname} action={'edit'} />
                    </Route>

                    {/* Have the 404 again here in case the urlPath is right, but the rest of the url is not. */}
                    <Route path="/" component={FourOhFour} />

                </Switch>
            </div>
        </ResourceContext.Provider>
    } else {
        console.log("ASTEROID: Unable to find resource.")
        return <FourOhFour />
    }
}


export default withRouter(DefaultController)



