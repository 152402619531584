import React, { useContext } from 'react'
import FieldBlock from './FieldBlock'

import { DataConnectionContext } from 'contexts/dataConnectionContext'
import { ResourceContext } from 'contexts/resourceContext'

const CategoryBlock = ({ resourceDisplayBlock }) => {
    let resource = useContext(ResourceContext)
    let dataConnection = useContext(DataConnectionContext)

    let item = dataConnection.data
    let { action, parentAction } = dataConnection

    let info = resourceDisplayBlock.info

    const renderFields = () => { return info.resourceFieldValues.map(resourceFieldValue => <FieldBlock resourceDisplayBlock={{ info: resourceFieldValue }} />)}

    if (info.category.checkPermission(parentAction, {}, item[resource.special_fields.owner_id_field])) {
        let cssId = info.category.processCustomCSS(action, resource)

        if (info.category.checkCustomDisplay(parentAction)) {
            return info.category.customDisplay(parentAction, info)
        } else {

            if (info.resourceFieldValues.length > 0) {
                return <div className={` ${info.category.options.section === 'false' ? "" : 'asteroid-page-section'} resource-category ${cssId}`}>
                    { info.category.renderTitle(parentAction) }

                    <div className="resource-category-items container">
                        {renderFields()}
                    </div>

                </div>
            }
        }
    } else { return "" }
}

export default CategoryBlock