import React, { useContext, useState } from 'react'
import { Form } from 'react-bootstrap'
import axios from 'axios'
import { withRouter, Link } from 'react-router-dom'
import api from 'helpers/api'

import { AppContext } from 'contexts/appContext'

const LogIn = () => {

    const [error, setError] = useState(null)
    const [formColor, setFormColor] = useState('var(--ll-light-color)')
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")

    const app = useContext(AppContext)

    const handleLogin = (e) => {
        setFormColor('var(--loading-color)')
        e.preventDefault();
        axios
            .post(api.loginPath(), { username, password })
            .then(res => {
                setFormColor('var(--success-color)')
                const user = res.data.user
                app.login(user, res.data.token)
                if (user.user_kind === 'end_user') {

                    setTimeout(() => {
                        window.location.replace('/?loggedIn=true')
                    }, 200)


                } else {
                    setTimeout(() => { window.location.replace('/') }, 200)
                }

            })
            .catch((err, res) => {
                if (err.response.data.message === "Invalid Credentials.") {
                    setError("The information you entered does not match our records. Please try again.")
                    setFormColor('var(--error-color)')
                    setTimeout(() => { setFormColor('var(--ll-light-color)') }, 2000)
                }
            });
    }

    return <div className="text-center container asteroid-page-section">

        <div className="content-box" style={{ backgroundColor: formColor }}>
            <h2>Login</h2>
            {error || ""}
            <Form onSubmit={handleLogin} >

                <Form.Group>
                    <Form.Label>Username</Form.Label>
                    <Form.Control
                        onChange={(e) => setUsername(e.target.value)} type="text"
                        name="username" placeholder="username"
                        value={username} />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                        onChange={(e) => setPassword(e.target.value)} type="password"
                        name="password" placeholder="Password"
                        value={password} />
                </Form.Group>

                <button type='submit'>Login</button>&nbsp;
                <Link to={'/auth/forgottenPassword'}>Forgot Your Password?</Link>
            </Form>
        </div>
    </div>

}


LogIn.contextType = AppContext

export default withRouter(LogIn)
